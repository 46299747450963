var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wrap wrap--products"
  }, [_c('main-header'), _c('main', {
    staticClass: "main",
    attrs: {
      "role": "main"
    }
  }, [_c('div', {
    staticClass: "container"
  }, [_c('section', {
    staticClass: "product-view-container"
  }, [_vm._m(0), _c('section', {
    staticClass: "product-details"
  }, [_vm._m(1), _vm._m(2), _c('div', {
    staticClass: "product-details__contents"
  }, [_vm._m(3), _c('div', {
    staticClass: "product-details__content"
  }, [_c('div', {
    staticClass: "product-review-container",
    attrs: {
      "data-tab-contents": "review"
    }
  }, [_vm._m(4), _c('div', {
    staticClass: "product-review__body"
  }, [_c('div', {
    staticClass: "review-box-container"
  }, [_c('div', {
    staticClass: "review-box"
  }, [_c('div', {
    staticClass: "review-box__head"
  }, [_c('div', {
    staticClass: "review-box__evaluation"
  }, [_c('div', {
    staticClass: "star-rating-container"
  }, [_c('span', {
    staticClass: "star-rating"
  }, [_c('svg', {
    staticClass: "star",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "x": "0px",
      "y": "0px",
      "viewBox": "0 0 512 488.2"
    }
  }, [_c('linearGradient', {
    attrs: {
      "id": "starOrder1",
      "x1": "0",
      "x2": "100%",
      "y1": "0",
      "y2": "0"
    }
  }, [_c('stop', {
    attrs: {
      "offset": "100%",
      "stop-color": "#ffdc64"
    }
  }), _c('stop', {
    attrs: {
      "offset": "100%",
      "stop-color": "#dddddd"
    }
  })], 1), _c('path', {
    attrs: {
      "d": "M499.9,176.4L334.1,161L268.2,8c-4.6-10.7-19.8-10.7-24.4,0l-65.9,153L12.1,176.4c-11.6,1.1-16.3,15.5-7.5,23.2l125.1,109.9L93,472c-2.6,11.4,9.7,20.3,19.7,14.3l143.2-85l143.2,85c10,6,22.3-3,19.8-14.3l-36.6-162.5l125.1-109.9C516.2,191.9,511.5,177.5,499.9,176.4",
      "fill": "url('#starOrder1')"
    }
  })], 1)]), _c('span', {
    staticClass: "star-rating"
  }, [_c('svg', {
    staticClass: "star",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "x": "0px",
      "y": "0px",
      "viewBox": "0 0 512 488.2"
    }
  }, [_c('linearGradient', {
    attrs: {
      "id": "starOrder2",
      "x1": "0",
      "x2": "100%",
      "y1": "0",
      "y2": "0"
    }
  }, [_c('stop', {
    attrs: {
      "offset": "100%",
      "stop-color": "#ffdc64"
    }
  }), _c('stop', {
    attrs: {
      "offset": "100%",
      "stop-color": "#dddddd"
    }
  })], 1), _c('path', {
    attrs: {
      "d": "M499.9,176.4L334.1,161L268.2,8c-4.6-10.7-19.8-10.7-24.4,0l-65.9,153L12.1,176.4c-11.6,1.1-16.3,15.5-7.5,23.2l125.1,109.9L93,472c-2.6,11.4,9.7,20.3,19.7,14.3l143.2-85l143.2,85c10,6,22.3-3,19.8-14.3l-36.6-162.5l125.1-109.9C516.2,191.9,511.5,177.5,499.9,176.4",
      "fill": "url('#starOrder2')"
    }
  })], 1)]), _c('span', {
    staticClass: "star-rating"
  }, [_c('svg', {
    staticClass: "star",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "x": "0px",
      "y": "0px",
      "viewBox": "0 0 512 488.2"
    }
  }, [_c('linearGradient', {
    attrs: {
      "id": "starOrder3",
      "x1": "0",
      "x2": "100%",
      "y1": "0",
      "y2": "0"
    }
  }, [_c('stop', {
    attrs: {
      "offset": "100%",
      "stop-color": "#ffdc64"
    }
  }), _c('stop', {
    attrs: {
      "offset": "100%",
      "stop-color": "#dddddd"
    }
  })], 1), _c('path', {
    attrs: {
      "d": "M499.9,176.4L334.1,161L268.2,8c-4.6-10.7-19.8-10.7-24.4,0l-65.9,153L12.1,176.4c-11.6,1.1-16.3,15.5-7.5,23.2l125.1,109.9L93,472c-2.6,11.4,9.7,20.3,19.7,14.3l143.2-85l143.2,85c10,6,22.3-3,19.8-14.3l-36.6-162.5l125.1-109.9C516.2,191.9,511.5,177.5,499.9,176.4",
      "fill": "url('#starOrder3')"
    }
  })], 1)]), _c('span', {
    staticClass: "star-rating"
  }, [_c('svg', {
    staticClass: "star",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "x": "0px",
      "y": "0px",
      "viewBox": "0 0 512 488.2"
    }
  }, [_c('linearGradient', {
    attrs: {
      "id": "starOrder4",
      "x1": "0",
      "x2": "100%",
      "y1": "0",
      "y2": "0"
    }
  }, [_c('stop', {
    attrs: {
      "offset": "100%",
      "stop-color": "#ffdc64"
    }
  }), _c('stop', {
    attrs: {
      "offset": "100%",
      "stop-color": "#dddddd"
    }
  })], 1), _c('path', {
    attrs: {
      "d": "M499.9,176.4L334.1,161L268.2,8c-4.6-10.7-19.8-10.7-24.4,0l-65.9,153L12.1,176.4c-11.6,1.1-16.3,15.5-7.5,23.2l125.1,109.9L93,472c-2.6,11.4,9.7,20.3,19.7,14.3l143.2-85l143.2,85c10,6,22.3-3,19.8-14.3l-36.6-162.5l125.1-109.9C516.2,191.9,511.5,177.5,499.9,176.4",
      "fill": "url('#starOrder4')"
    }
  })], 1)]), _c('span', {
    staticClass: "star-rating"
  }, [_c('svg', {
    staticClass: "star",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "x": "0px",
      "y": "0px",
      "viewBox": "0 0 512 488.2"
    }
  }, [_c('linearGradient', {
    attrs: {
      "id": "starOrder5",
      "x1": "0",
      "x2": "100%",
      "y1": "0",
      "y2": "0"
    }
  }, [_c('stop', {
    attrs: {
      "offset": "0%",
      "stop-color": "#ffdc64"
    }
  }), _c('stop', {
    attrs: {
      "offset": "0%",
      "stop-color": "#dddddd"
    }
  })], 1), _c('path', {
    attrs: {
      "d": "M499.9,176.4L334.1,161L268.2,8c-4.6-10.7-19.8-10.7-24.4,0l-65.9,153L12.1,176.4c-11.6,1.1-16.3,15.5-7.5,23.2l125.1,109.9L93,472c-2.6,11.4,9.7,20.3,19.7,14.3l143.2-85l143.2,85c10,6,22.3-3,19.8-14.3l-36.6-162.5l125.1-109.9C516.2,191.9,511.5,177.5,499.9,176.4",
      "fill": "url('#starOrder5')"
    }
  })], 1)])])]), _c('span', {
    staticClass: "review-box__id"
  }, [_vm._v(" id1234**")]), _c('span', {
    staticClass: "review-box__date"
  }, [_vm._v("2020-01-01")])]), _vm._m(5)]), _c('div', {
    staticClass: "review-box"
  }, [_c('div', {
    staticClass: "review-box__head"
  }, [_c('div', {
    staticClass: "review-box__evaluation"
  }, [_c('div', {
    staticClass: "star-rating-container"
  }, [_c('span', {
    staticClass: "star-rating"
  }, [_c('svg', {
    staticClass: "star",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "x": "0px",
      "y": "0px",
      "viewBox": "0 0 512 488.2"
    }
  }, [_c('linearGradient', {
    attrs: {
      "id": "starOrder1",
      "x1": "0",
      "x2": "100%",
      "y1": "0",
      "y2": "0"
    }
  }, [_c('stop', {
    attrs: {
      "offset": "100%",
      "stop-color": "#ffdc64"
    }
  }), _c('stop', {
    attrs: {
      "offset": "100%",
      "stop-color": "#dddddd"
    }
  })], 1), _c('path', {
    attrs: {
      "d": "M499.9,176.4L334.1,161L268.2,8c-4.6-10.7-19.8-10.7-24.4,0l-65.9,153L12.1,176.4c-11.6,1.1-16.3,15.5-7.5,23.2l125.1,109.9L93,472c-2.6,11.4,9.7,20.3,19.7,14.3l143.2-85l143.2,85c10,6,22.3-3,19.8-14.3l-36.6-162.5l125.1-109.9C516.2,191.9,511.5,177.5,499.9,176.4",
      "fill": "url('#starOrder1')"
    }
  })], 1)]), _c('span', {
    staticClass: "star-rating"
  }, [_c('svg', {
    staticClass: "star",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "x": "0px",
      "y": "0px",
      "viewBox": "0 0 512 488.2"
    }
  }, [_c('linearGradient', {
    attrs: {
      "id": "starOrder2",
      "x1": "0",
      "x2": "100%",
      "y1": "0",
      "y2": "0"
    }
  }, [_c('stop', {
    attrs: {
      "offset": "100%",
      "stop-color": "#ffdc64"
    }
  }), _c('stop', {
    attrs: {
      "offset": "100%",
      "stop-color": "#dddddd"
    }
  })], 1), _c('path', {
    attrs: {
      "d": "M499.9,176.4L334.1,161L268.2,8c-4.6-10.7-19.8-10.7-24.4,0l-65.9,153L12.1,176.4c-11.6,1.1-16.3,15.5-7.5,23.2l125.1,109.9L93,472c-2.6,11.4,9.7,20.3,19.7,14.3l143.2-85l143.2,85c10,6,22.3-3,19.8-14.3l-36.6-162.5l125.1-109.9C516.2,191.9,511.5,177.5,499.9,176.4",
      "fill": "url('#starOrder2')"
    }
  })], 1)]), _c('span', {
    staticClass: "star-rating"
  }, [_c('svg', {
    staticClass: "star",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "x": "0px",
      "y": "0px",
      "viewBox": "0 0 512 488.2"
    }
  }, [_c('linearGradient', {
    attrs: {
      "id": "starOrder3",
      "x1": "0",
      "x2": "100%",
      "y1": "0",
      "y2": "0"
    }
  }, [_c('stop', {
    attrs: {
      "offset": "100%",
      "stop-color": "#ffdc64"
    }
  }), _c('stop', {
    attrs: {
      "offset": "100%",
      "stop-color": "#dddddd"
    }
  })], 1), _c('path', {
    attrs: {
      "d": "M499.9,176.4L334.1,161L268.2,8c-4.6-10.7-19.8-10.7-24.4,0l-65.9,153L12.1,176.4c-11.6,1.1-16.3,15.5-7.5,23.2l125.1,109.9L93,472c-2.6,11.4,9.7,20.3,19.7,14.3l143.2-85l143.2,85c10,6,22.3-3,19.8-14.3l-36.6-162.5l125.1-109.9C516.2,191.9,511.5,177.5,499.9,176.4",
      "fill": "url('#starOrder3')"
    }
  })], 1)]), _c('span', {
    staticClass: "star-rating"
  }, [_c('svg', {
    staticClass: "star",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "x": "0px",
      "y": "0px",
      "viewBox": "0 0 512 488.2"
    }
  }, [_c('linearGradient', {
    attrs: {
      "id": "starOrder4",
      "x1": "0",
      "x2": "100%",
      "y1": "0",
      "y2": "0"
    }
  }, [_c('stop', {
    attrs: {
      "offset": "100%",
      "stop-color": "#ffdc64"
    }
  }), _c('stop', {
    attrs: {
      "offset": "100%",
      "stop-color": "#dddddd"
    }
  })], 1), _c('path', {
    attrs: {
      "d": "M499.9,176.4L334.1,161L268.2,8c-4.6-10.7-19.8-10.7-24.4,0l-65.9,153L12.1,176.4c-11.6,1.1-16.3,15.5-7.5,23.2l125.1,109.9L93,472c-2.6,11.4,9.7,20.3,19.7,14.3l143.2-85l143.2,85c10,6,22.3-3,19.8-14.3l-36.6-162.5l125.1-109.9C516.2,191.9,511.5,177.5,499.9,176.4",
      "fill": "url('#starOrder4')"
    }
  })], 1)]), _c('span', {
    staticClass: "star-rating"
  }, [_c('svg', {
    staticClass: "star",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "x": "0px",
      "y": "0px",
      "viewBox": "0 0 512 488.2"
    }
  }, [_c('linearGradient', {
    attrs: {
      "id": "starOrder5",
      "x1": "0",
      "x2": "100%",
      "y1": "0",
      "y2": "0"
    }
  }, [_c('stop', {
    attrs: {
      "offset": "0%",
      "stop-color": "#ffdc64"
    }
  }), _c('stop', {
    attrs: {
      "offset": "0%",
      "stop-color": "#dddddd"
    }
  })], 1), _c('path', {
    attrs: {
      "d": "M499.9,176.4L334.1,161L268.2,8c-4.6-10.7-19.8-10.7-24.4,0l-65.9,153L12.1,176.4c-11.6,1.1-16.3,15.5-7.5,23.2l125.1,109.9L93,472c-2.6,11.4,9.7,20.3,19.7,14.3l143.2-85l143.2,85c10,6,22.3-3,19.8-14.3l-36.6-162.5l125.1-109.9C516.2,191.9,511.5,177.5,499.9,176.4",
      "fill": "url('#starOrder5')"
    }
  })], 1)])])]), _c('span', {
    staticClass: "review-box__id"
  }, [_vm._v(" id1234**")]), _c('span', {
    staticClass: "review-box__date"
  }, [_vm._v("2020-01-01")])]), _vm._m(6)])])])])]), _vm._m(7), _vm._m(8)])])])])]), _c('main-footer')], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "product-intro"
  }, [_c('div', {
    staticClass: "product-intro__thumb"
  }, [_c('div', {
    staticClass: "product-intro__thumb-main"
  }, [_c('img', {
    attrs: {
      "src": "/images/sample/product-thumbnail-large.jpg",
      "alt": ""
    }
  })]), _c('ul', {
    staticClass: "product-intro__thumb-lists"
  }, [_c('li', {
    staticClass: "product-intro__thumb-list"
  }, [_c('div', {
    staticClass: "product-intro__thumb-button",
    attrs: {
      "role": "button",
      "aria-pressed": "true"
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/sample/product-thumbnail-large.jpg",
      "alt": ""
    }
  })])]), _c('li', {
    staticClass: "product-intro__thumb-list"
  }, [_c('div', {
    staticClass: "product-intro__thumb-button",
    attrs: {
      "role": "button",
      "aria-pressed": "false"
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/sample/product-thumbnail-large.jpg",
      "alt": ""
    }
  })])]), _c('li', {
    staticClass: "product-intro__thumb-list"
  }, [_c('div', {
    staticClass: "product-intro__thumb-button",
    attrs: {
      "role": "button",
      "aria-pressed": "false"
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/sample/product-thumbnail-large.jpg",
      "alt": ""
    }
  })])]), _c('li', {
    staticClass: "product-intro__thumb-list"
  }, [_c('div', {
    staticClass: "product-intro__thumb-button",
    attrs: {
      "role": "button",
      "aria-pressed": "false"
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/sample/product-thumbnail-large.jpg",
      "alt": ""
    }
  })])]), _c('li', {
    staticClass: "product-intro__thumb-list"
  }, [_c('div', {
    staticClass: "product-intro__thumb-button",
    attrs: {
      "role": "button",
      "aria-pressed": "false"
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/sample/product-thumbnail-large.jpg",
      "alt": ""
    }
  })])])])]), _c('div', {
    staticClass: "product-intro__info"
  }, [_c('div', {
    staticClass: "product-intro__name"
  }, [_c('h1', {
    staticClass: "product-intro__name-text"
  }, [_vm._v("[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개")])]), _c('div', {
    staticClass: "product-intro__table"
  }, [_c('div', {
    staticClass: "product-intro__table-row-group"
  }, [_c('div', {
    staticClass: "product-intro__table-row"
  }, [_c('div', {
    staticClass: "product-intro__table-th"
  }, [_vm._v("판매가")]), _c('div', {
    staticClass: "product-intro__table-td"
  }, [_c('span', {
    staticClass: "product-intro__price"
  }, [_c('span', {
    staticClass: "percent"
  }, [_vm._v("25%")]), _c('span', {
    staticClass: "current"
  }, [_c('em', {
    staticClass: "num"
  }, [_vm._v("15,000")]), _vm._v("원")]), _c('span', {
    staticClass: "origin"
  }, [_vm._v("18,000원")])])])]), _c('div', {
    staticClass: "product-intro__table-row"
  }, [_c('div', {
    staticClass: "product-intro__table-th"
  }, [_vm._v("배송비")]), _c('div', {
    staticClass: "product-intro__table-td"
  }, [_vm._v("무료배송")])])]), _c('div', {
    staticClass: "product-intro__table-row-group"
  }, [_c('div', {
    staticClass: "product-intro__table-row"
  }, [_c('div', {
    staticClass: "product-intro__table-th"
  }, [_vm._v("옵션선택")]), _c('div', {
    staticClass: "product-intro__table-td"
  }, [_c('select', {
    staticClass: "product-intro__select",
    attrs: {
      "name": ""
    }
  }, [_c('option', {
    attrs: {
      "value": "",
      "disabled": "",
      "selected": ""
    }
  }, [_vm._v("옵션을 선택하세요.")]), _c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("닭고기")])])])])])]), _c('div', {
    staticClass: "product-intro__option-box-group"
  }, [_c('div', {
    staticClass: "product-option-box"
  }, [_c('button', {
    staticClass: "product-option-box__delete",
    attrs: {
      "type": "button"
    }
  }, [_c('span', {
    staticClass: "blind"
  }, [_vm._v("삭제")])]), _c('span', {
    staticClass: "product-option-box__title"
  }, [_vm._v("옵션: 닭고기")]), _c('div', {
    staticClass: "product-option-box__flex"
  }, [_c('div', {
    staticClass: "spinner"
  }, [_c('button', {
    staticClass: "spinner__minus",
    attrs: {
      "type": "button"
    }
  }), _c('input', {
    staticClass: "spinner__num",
    attrs: {
      "type": "number",
      "name": "",
      "value": "1"
    }
  }), _c('button', {
    staticClass: "spinner__plus",
    attrs: {
      "type": "button"
    }
  })]), _c('div', {
    staticClass: "product-option-box__price"
  }, [_c('span', {
    staticClass: "price"
  }, [_c('em', {
    staticClass: "num"
  }, [_vm._v("15,000")]), _vm._v("원")])])])])]), _c('div', {
    staticClass: "product-intro__total"
  }, [_c('span', {
    staticClass: "price"
  }, [_vm._v("총"), _c('em', {
    staticClass: "num"
  }, [_vm._v("15,000")]), _vm._v("원")])]), _c('div', {
    staticClass: "product-intro__buttons"
  }, [_c('a', {
    staticClass: "button button--border-primary",
    attrs: {
      "href": "/mypage/cart"
    }
  }, [_c('span', [_vm._v("장바구니")])]), _c('a', {
    staticClass: "button button--primary",
    attrs: {
      "href": "/shop/order"
    }
  }, [_c('span', [_vm._v("바로구매")])])])]), _c('div', {
    staticClass: "product-intro__coupon"
  }, [_c('span', {
    staticClass: "product-intro__coupon-title"
  }, [_c('b', [_vm._v("이 상품!")]), _vm._v(" "), _c('br'), _vm._v("할인가능쿠폰")]), _c('ul', {
    staticClass: "product-intro__coupon-lists"
  }, [_c('li', [_c('a', {
    staticClass: "button",
    attrs: {
      "href": "#",
      "onclick": "return:false;"
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/sample/coupon1.jpg",
      "alt": ""
    }
  })])]), _c('li', [_c('a', {
    staticClass: "button",
    attrs: {
      "href": "#",
      "onclick": "return:false;"
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/sample/coupon2.jpg",
      "alt": ""
    }
  })])]), _c('li', [_c('a', {
    staticClass: "button",
    attrs: {
      "href": "#",
      "onclick": "return:false;"
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/sample/coupon3.jpg",
      "alt": ""
    }
  })])]), _c('li', [_c('a', {
    staticClass: "button",
    attrs: {
      "href": "#",
      "onclick": "return:false;"
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/sample/coupon4.jpg",
      "alt": ""
    }
  })])])])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('nav', {
    staticClass: "product-details__navigation"
  }, [_c('div', {
    staticClass: "product-details__navigation-inner"
  }, [_c('ul', {
    staticClass: "product-details__nav"
  }, [_c('li', [_c('button', {
    staticClass: "product-details__nav-tab",
    attrs: {
      "type": "button",
      "data-tab-button": "main",
      "aria-pressed": "true"
    }
  }, [_c('span', [_vm._v("상품정보")])])]), _c('li', [_c('button', {
    staticClass: "product-details__nav-tab",
    attrs: {
      "type": "button",
      "data-tab-button": "review",
      "aria-pressed": "false"
    }
  }, [_c('span', [_vm._v("구매후기")])])]), _c('li', [_c('button', {
    staticClass: "product-details__nav-tab",
    attrs: {
      "type": "button",
      "data-tab-button": "qna",
      "aria-pressed": "false"
    }
  }, [_c('span', [_vm._v("상품문의")])])]), _c('li', [_c('button', {
    staticClass: "product-details__nav-tab",
    attrs: {
      "type": "button",
      "data-tab-button": "delivery",
      "aria-pressed": "false"
    }
  }, [_c('span', [_vm._v("반품/교환")])])])])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "product-details__sidebar"
  }, [_c('div', {
    staticClass: "product-sidebar",
    attrs: {
      "data-sidebar-fixed": "off"
    }
  }, [_c('div', {
    staticClass: "product-sidebar__contents"
  }, [_c('div', {
    staticClass: "product-sidebar__top"
  }, [_c('span', {
    staticClass: "product-sidebar__title"
  }, [_vm._v("[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개")]), _c('div', {
    staticClass: "product-sidebar__option-row-group"
  }, [_c('div', {
    staticClass: "product-sidebar__option-row"
  }, [_c('div', {
    staticClass: "product-sidebar__option-th"
  }, [_vm._v("옵션선택")]), _c('div', {
    staticClass: "product-sidebar__option-td"
  }, [_c('select', {
    staticClass: "product-intro__select",
    attrs: {
      "name": ""
    }
  }, [_c('option', {
    attrs: {
      "value": "",
      "disabled": "",
      "select": ""
    }
  }, [_vm._v("옵션을 선택하세요.")]), _c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("닭고기")])])])])]), _c('div', {
    staticClass: "product-sidebar__option-box-group"
  }, [_c('div', {
    staticClass: "product-option-box"
  }, [_c('button', {
    staticClass: "product-option-box__delete",
    attrs: {
      "type": "button"
    }
  }, [_c('span', {
    staticClass: "blind"
  }, [_vm._v("삭제")])]), _c('span', {
    staticClass: "product-option-box__title"
  }, [_vm._v("옵션: 닭고기")]), _c('div', {
    staticClass: "product-option-box__flex"
  }, [_c('div', {
    staticClass: "spinner"
  }, [_c('button', {
    staticClass: "spinner__minus",
    attrs: {
      "type": "button"
    }
  }), _c('input', {
    staticClass: "spinner__num",
    attrs: {
      "type": "number",
      "name": "",
      "value": "1"
    }
  }), _c('button', {
    staticClass: "spinner__plus",
    attrs: {
      "type": "button"
    }
  })]), _c('div', {
    staticClass: "product-option-box__price"
  }, [_c('span', {
    staticClass: "price"
  }, [_c('em', {
    staticClass: "num"
  }, [_vm._v("15,000")]), _vm._v("원")])])])])])]), _c('div', {
    staticClass: "product-sidebar__bottom"
  }, [_c('div', {
    staticClass: "product-sidebar__total"
  }, [_c('span', {
    staticClass: "price"
  }, [_vm._v("총"), _c('em', {
    staticClass: "num"
  }, [_vm._v("15,000")]), _vm._v("원")])]), _c('div', {
    staticClass: "product-sidebar__buttons"
  }, [_c('button', {
    staticClass: "button button--border-primary"
  }, [_c('span', [_vm._v("장바구니")])]), _c('button', {
    staticClass: "button button--primary"
  }, [_c('span', [_vm._v("바로구매")])])]), _c('div', {
    staticClass: "product-sidebar__methods"
  })])])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "product-details__content"
  }, [_c('div', {
    staticClass: "product-detail-container",
    attrs: {
      "data-tab-contents": "main"
    }
  }, [_c('section', {
    staticClass: "product-main"
  }, [_c('div', {
    staticClass: "product-main__image"
  }, [_c('img', {
    attrs: {
      "src": "/images/sample/product-main-image.jpg",
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "product-main__title"
  }, [_c('span', {
    staticClass: "product-main__title-sub"
  }, [_vm._v("돼지함량 97%의 동결건조 주식")]), _c('h2', {
    staticClass: "product-main__title-main"
  }, [_vm._v("DOG[빅스비] 러블 돼지 2종")])]), _c('div', {
    staticClass: "product-main__main-pr"
  }, [_c('p', [_vm._v("동결건조 사료는 영양소를 야생에 먹던 자연식에 무척 가깝게 구현해냈다고 하죠동결건조 사료는 영양소를 야생에 먹던 자연식에 무척 가깝게 구현해냈다고 하죠동결건조 사료는 영양소를 야생에 먹던 자연식에 무척 가깝게 구현해냈다고 하죠 동결건조 사료는 영양소를 야생에 먹던 자연식에 무척 가깝게 구현해냈다고 하죠동결건조 사료는 영양소를 야생에 먹던 자연식에 무척 가깝게 구현해냈다고 하죠")])])]), _c('section', {
    staticClass: "product-composition"
  }, [_c('section', {
    staticClass: "product-compose"
  }, [_c('h3', {
    staticClass: "product-compose__title-main"
  }, [_vm._v("Check Point")]), _c('h4', {
    staticClass: "product-compose__title-sub"
  }, [_vm._v("브랜드 & 생산업체 정보가 노출됩니다.")]), _c('div', {
    staticClass: "grid"
  }, [_c('div', {
    staticClass: "grid-12"
  }, [_c('h5', {
    staticClass: "title"
  }, [_vm._v("재료와 성분")]), _c('ul', [_c('li', [_vm._v("돼지 함량 97%")]), _c('li', [_vm._v("돼지의 원육, 뼈, 내장을 모두함유 ")]), _c('li', [_vm._v("식이섬유가 풍부한 호박, 오메가 3&6을 함유한 연어오일")]), _c('li', [_vm._v("그레인 프리")])])]), _c('div', {
    staticClass: "grid-12"
  }, [_c('h5', {
    staticClass: "title"
  }, [_vm._v("생산 유통 과정")]), _c('ul', [_c('li', [_vm._v("동결건조 방식으로제조해 영양소와 풍미 보존")]), _c('li', [_vm._v("보관과 급여가 간편한 지퍼백 패키지 적용")]), _c('li', [_vm._v("기호성 테스트용 128g과 신선하게 먹기 좋은 340g 용량")])])])])]), _c('section', {
    staticClass: "product-compose"
  }, [_c('h3', {
    staticClass: "product-compose__title-main"
  }, [_vm._v("Kong’s Pick")]), _c('h4', {
    staticClass: "product-compose__title-sub"
  }, [_vm._v("01~02. 러블 돼지")]), _c('div', {
    staticClass: "grid"
  }, [_c('div', {
    staticClass: "grid-24"
  }, [_c('ul', [_c('li', [_c('b', [_vm._v("선택 가이드 :")]), _vm._v(" ①1팩(128g). ②1팩(340g) 중 선택하세요.")]), _c('li', [_c('b', [_vm._v("주요 원재료 :")]), _vm._v(" 닭고기(뼈,심장,간포함), 호박, 코코넛오일, 연어오일 등")]), _c('li', [_c('b', [_vm._v("특징 :")]), _vm._v(" 항생제와 호르몬제 없이 방목하여 키운 닭 함량 98%의 동결건조 주식으로, 뼈와 내장을 모두 포함해 칼슘과 인, 미네랄이 풍부해요. 물에 풀면 고소한 향이 살아납니다.")])])])]), _c('div', {
    staticClass: "table"
  }, [_c('dl', {
    staticClass: "tr"
  }, [_c('dt', {
    staticClass: "th"
  }, [_vm._v("반려견무게")]), _c('dd', {
    staticClass: "td"
  }, [_vm._v("3kg 미만")]), _c('dd', {
    staticClass: "td"
  }, [_vm._v("3kg이상 ~ 5kg 미만")]), _c('dd', {
    staticClass: "td"
  }, [_vm._v("5kg이상 ~ 10kg 미만")]), _c('dd', {
    staticClass: "td"
  }, [_vm._v("10kg이상 ~ 15kg 미만")])]), _c('dl', {
    staticClass: "tr"
  }, [_c('dt', {
    staticClass: "th"
  }, [_vm._v("권장 급여량")]), _c('dd', {
    staticClass: "td"
  }, [_vm._v("15g~20g")]), _c('dd', {
    staticClass: "td"
  }, [_vm._v("15g~20g")]), _c('dd', {
    staticClass: "td"
  }, [_vm._v("15g~20g")]), _c('dd', {
    staticClass: "td"
  }, [_vm._v("15g~20g")])])])])]), _c('section', {
    staticClass: "product-sub"
  }, [_c('img', {
    attrs: {
      "src": "/images/sample/product-detail-image.jpg",
      "alt": ""
    }
  })])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "product-review__head"
  }, [_c('div', {
    staticClass: "product-review__order"
  }, [_c('span', {
    staticClass: "product-review__total"
  }, [_vm._v("구매후기 (10)")]), _c('span', {
    staticClass: "product-review__group"
  }, [_c('ul', {
    staticClass: "product-review__group-lists"
  }, [_c('li', [_c('a', {
    staticClass: "on"
  }, [_vm._v("인기순")])]), _c('li', [_c('a', [_vm._v("최신순")])])])])]), _c('div', {
    staticClass: "product-review__buttons"
  }, [_c('div', {
    staticClass: "button button--border-primary"
  }, [_vm._v("구매후기 등록")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "review-box__body"
  }, [_c('div', {
    staticClass: "review-box__texts"
  }, [_c('p', {
    staticClass: "review-box__text"
  }, [_vm._v(" 상품 후기 내용이 옵니다. 상품 후기 내용이 옵니다. 상품 후기 내용이 옵니다. 상품 후기 내용이 옵니다. 상품 후기 내용이 옵니다. 상품 후기 내용이 옵니다. 상품 후기 내용이 옵니다. 상품 후기 내용이 옵니다. 상품 후기 내용이 옵니다. 상품 후기 내용이 옵니다. 상품 후기 내용이 옵니다. 상품 후기 내용이 옵니다. ")])]), _c('div', {
    staticClass: "review-box__flex"
  }, [_c('ul', {
    staticClass: "review-box__photo-lists"
  }, [_c('li', {
    staticClass: "review-box__photo-list"
  }, [_c('div', {
    staticClass: "review-box__photo",
    attrs: {
      "role": "button"
    }
  }, [_c('img', {
    attrs: {
      "src": "",
      "alt": ""
    }
  })])]), _c('li', {
    staticClass: "review-box__photo-list"
  }, [_c('div', {
    staticClass: "review-box__photo",
    attrs: {
      "role": "button"
    }
  }, [_c('img', {
    attrs: {
      "src": "",
      "alt": ""
    }
  })])]), _c('li', {
    staticClass: "review-box__photo-list"
  }, [_c('div', {
    staticClass: "review-box__photo",
    attrs: {
      "role": "button"
    }
  }, [_c('img', {
    attrs: {
      "src": "",
      "alt": ""
    }
  })])]), _c('li', {
    staticClass: "review-box__photo-list"
  }, [_c('div', {
    staticClass: "review-box__photo",
    attrs: {
      "role": "button"
    }
  }, [_c('img', {
    attrs: {
      "src": "",
      "alt": ""
    }
  })])]), _c('li', {
    staticClass: "review-box__photo-list"
  }, [_c('div', {
    staticClass: "review-box__photo",
    attrs: {
      "role": "button"
    }
  }, [_c('img', {
    attrs: {
      "src": "",
      "alt": ""
    }
  })])]), _c('li', {
    staticClass: "review-box__photo-list"
  }, [_c('div', {
    staticClass: "review-box__photo",
    attrs: {
      "role": "button"
    }
  }, [_c('img', {
    attrs: {
      "src": "",
      "alt": ""
    }
  })])])]), _c('div', {
    staticClass: "review-box__thumbup"
  }, [_c('span', {
    staticClass: "review-box__thumbup-title"
  }, [_vm._v("이 리뷰가 도움이 되었나요?")]), _c('button', {
    staticClass: "button",
    attrs: {
      "type": "button",
      "aria-pressed": "true"
    }
  }, [_c('span', [_vm._v("네 "), _c('b', [_vm._v("+5")])])]), _c('button', {
    staticClass: "button",
    attrs: {
      "type": "button",
      "aria-pressed": "false"
    }
  }, [_c('span', [_vm._v("아니요 "), _c('b', [_vm._v("-1")])])])])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "review-box__body"
  }, [_c('div', {
    staticClass: "review-box__texts"
  }, [_c('p', {
    staticClass: "review-box__text"
  }, [_vm._v(" 상품 후기 내용이 옵니다. 상품 후기 내용이 옵니다. 상품 후기 내용이 옵니다. 상품 후기 내용이 옵니다. 상품 후기 내용이 옵니다. 상품 후기 내용이 옵니다. 상품 후기 내용이 옵니다. 상품 후기 내용이 옵니다. 상품 후기 내용이 옵니다. 상품 후기 내용이 옵니다. 상품 후기 내용이 옵니다. 상품 후기 내용이 옵니다. ")])]), _c('div', {
    staticClass: "review-box__flex"
  }, [_c('ul', {
    staticClass: "review-box__photo-lists"
  }, [_c('li', {
    staticClass: "review-box__photo-list"
  }, [_c('div', {
    staticClass: "review-box__photo",
    attrs: {
      "role": "button"
    }
  }, [_c('img', {
    attrs: {
      "src": "",
      "alt": ""
    }
  })])]), _c('li', {
    staticClass: "review-box__photo-list"
  }, [_c('div', {
    staticClass: "review-box__photo",
    attrs: {
      "role": "button"
    }
  }, [_c('img', {
    attrs: {
      "src": "",
      "alt": ""
    }
  })])]), _c('li', {
    staticClass: "review-box__photo-list"
  }, [_c('div', {
    staticClass: "review-box__photo",
    attrs: {
      "role": "button"
    }
  }, [_c('img', {
    attrs: {
      "src": "",
      "alt": ""
    }
  })])]), _c('li', {
    staticClass: "review-box__photo-list"
  }, [_c('div', {
    staticClass: "review-box__photo",
    attrs: {
      "role": "button"
    }
  }, [_c('img', {
    attrs: {
      "src": "",
      "alt": ""
    }
  })])]), _c('li', {
    staticClass: "review-box__photo-list"
  }, [_c('div', {
    staticClass: "review-box__photo",
    attrs: {
      "role": "button"
    }
  }, [_c('img', {
    attrs: {
      "src": "",
      "alt": ""
    }
  })])]), _c('li', {
    staticClass: "review-box__photo-list"
  }, [_c('div', {
    staticClass: "review-box__photo",
    attrs: {
      "role": "button"
    }
  }, [_c('img', {
    attrs: {
      "src": "",
      "alt": ""
    }
  })])])]), _c('div', {
    staticClass: "review-box__thumbup"
  }, [_c('span', {
    staticClass: "review-box__thumbup-title"
  }, [_vm._v("이 리뷰가 도움이 되었나요?")]), _c('button', {
    staticClass: "button",
    attrs: {
      "type": "button",
      "aria-pressed": "true"
    }
  }, [_c('span', [_vm._v("네 "), _c('b', [_vm._v("+5")])])]), _c('button', {
    staticClass: "button",
    attrs: {
      "type": "button",
      "aria-pressed": "false"
    }
  }, [_c('span', [_vm._v("아니요 "), _c('b', [_vm._v("-1")])])])])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "product-details__content"
  }, [_c('div', {
    staticClass: "product-qna-container",
    attrs: {
      "data-tab-contents": "qna"
    }
  }, [_c('div', {
    staticClass: "product-qna__head"
  }, [_c('div', {
    staticClass: "product-qna__form"
  }, [_c('form', {
    staticClass: "product-qna__form-inner",
    attrs: {
      "action": ""
    }
  }, [_c('div', {
    staticClass: "product-qna__search"
  }, [_c('input', {
    staticClass: "input",
    attrs: {
      "type": "text",
      "name": "",
      "value": ""
    }
  }), _c('button', {
    staticClass: "button",
    attrs: {
      "type": "submit"
    }
  })]), _c('select', {
    staticClass: "product-qna__select",
    attrs: {
      "name": ""
    }
  }, [_c('option', {
    attrs: {
      "value": "",
      "disabled": "",
      "selected": ""
    }
  }, [_vm._v("항목을 선택해주세요.")]), _c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("옵션1")]), _c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("옵션2")])])])]), _c('div', {
    staticClass: "product-qna__buttons"
  }, [_c('div', {
    staticClass: "button button--border-primary"
  }, [_vm._v("문의하기")])])]), _c('div', {
    staticClass: "product-qna__body"
  }, [_c('div', {
    staticClass: "product-table"
  }, [_c('div', {
    staticClass: "tr"
  }, [_c('div', {
    staticClass: "th"
  }, [_vm._v("번호")]), _c('div', {
    staticClass: "th"
  }, [_vm._v("제목")]), _c('div', {
    staticClass: "th"
  }, [_vm._v("작성자")]), _c('div', {
    staticClass: "th"
  }, [_vm._v("작성일")]), _c('div', {
    staticClass: "th"
  }, [_vm._v("처리현황")])]), _c('div', {
    staticClass: "tr"
  }, [_c('div', {
    staticClass: "td"
  }, [_vm._v("3")]), _c('div', {
    staticClass: "td"
  }, [_c('a', {
    staticClass: "link",
    attrs: {
      "href": ""
    }
  }, [_vm._v("상품문의 제목이 옵니다. 상품문의 제목이 옵니다. 상품문의 제목이 옵니다. 상품문의 제목이 옵니다. 상품문의 제목이 옵니다. 상품문의 제목이 옵니다. ")])]), _c('div', {
    staticClass: "td"
  }, [_vm._v(" id1234**")]), _c('div', {
    staticClass: "td"
  }, [_vm._v("2020-01-01")]), _c('div', {
    staticClass: "td"
  }, [_vm._v("답변대기")])]), _c('div', {
    staticClass: "tr"
  }, [_c('div', {
    staticClass: "td"
  }, [_vm._v("2")]), _c('div', {
    staticClass: "td"
  }, [_c('a', {
    staticClass: "link",
    attrs: {
      "href": ""
    }
  }, [_vm._v("상품문의 제목이 옵니다.")])]), _c('div', {
    staticClass: "td"
  }, [_vm._v(" id1234**")]), _c('div', {
    staticClass: "td"
  }, [_vm._v("2020-01-01")]), _c('div', {
    staticClass: "td"
  }, [_vm._v("답변대기")])]), _c('div', {
    staticClass: "tr"
  }, [_c('div', {
    staticClass: "td"
  }, [_vm._v("1")]), _c('div', {
    staticClass: "td"
  }, [_c('a', {
    staticClass: "link",
    attrs: {
      "href": ""
    }
  }, [_vm._v("상품문의 제목이 옵니다.")])]), _c('div', {
    staticClass: "td"
  }, [_vm._v(" id1234**")]), _c('div', {
    staticClass: "td"
  }, [_vm._v("2020-01-01")]), _c('div', {
    staticClass: "td"
  }, [_vm._v("답변대기")])])])])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "product-details__content"
  }, [_c('div', {
    staticClass: "product-delivery-container",
    attrs: {
      "data-tab-contents": "delivery"
    }
  }, [_c('div', {
    staticClass: "product-delivery"
  }, [_c('div', {
    staticClass: "product-delivery__col"
  }, [_c('span', {
    staticClass: "title"
  }, [_vm._v("반품")]), _c('span', {
    staticClass: "subtitle"
  }, [_vm._v("상품이 마음에 안 들어 돈으로 환불 받을 경우")]), _c('div', {
    staticClass: "table"
  }, [_c('div', {
    staticClass: "tr"
  }, [_c('div', {
    staticClass: "th"
  }, [_vm._v("반품신청​")]), _c('div', {
    staticClass: "td"
  }, [_vm._v("구매자 책임사유시 반품/배송비 결제​")])]), _c('div', {
    staticClass: "tr"
  }, [_c('div', {
    staticClass: "th"
  }, [_vm._v("발송하기​")]), _c('div', {
    staticClass: "td"
  }, [_vm._v("1~2일 이내 택배기사 방문​")])]), _c('div', {
    staticClass: "tr"
  }, [_c('div', {
    staticClass: "th"
  }, [_vm._v("반품접수 완료​")]), _c('div', {
    staticClass: "td"
  }, [_vm._v("벨스리몰에 반품상품 전달​")])]), _c('div', {
    staticClass: "tr"
  }, [_c('div', {
    staticClass: "th"
  }, [_vm._v("반품완료​")]), _c('div', {
    staticClass: "td"
  }, [_vm._v("확인 후 환불처리​")])])])]), _c('div', {
    staticClass: "product-delivery__col"
  }, [_c('span', {
    staticClass: "title"
  }, [_vm._v("교환")]), _c('span', {
    staticClass: "subtitle"
  }, [_vm._v("주문상품과 다르게 배송되어 다른 상품과 바꿀때​")]), _c('div', {
    staticClass: "table"
  }, [_c('div', {
    staticClass: "tr"
  }, [_c('div', {
    staticClass: "th"
  }, [_vm._v("교환신청​")]), _c('div', {
    staticClass: "td"
  }, [_vm._v("구매자 책임사유시 반품/배송비 결제​")])]), _c('div', {
    staticClass: "tr"
  }, [_c('div', {
    staticClass: "th"
  }, [_vm._v("발송하기​")]), _c('div', {
    staticClass: "td"
  }, [_vm._v("1~2일 이내 택배기사 방문​")])]), _c('div', {
    staticClass: "tr"
  }, [_c('div', {
    staticClass: "th"
  }, [_vm._v("교환접수​")]), _c('div', {
    staticClass: "td"
  }, [_vm._v("밸리스몰에 교환상품 전달​")])]), _c('div', {
    staticClass: "tr"
  }, [_c('div', {
    staticClass: "th"
  }, [_vm._v("상품 재배송​")]), _c('div', {
    staticClass: "td"
  }, [_vm._v("확인 후 교환​")])]), _c('div', {
    staticClass: "tr"
  }, [_c('div', {
    staticClass: "th"
  }, [_vm._v("교환완료​")]), _c('div', {
    staticClass: "td"
  })])])]), _c('div', {
    staticClass: "product-delivery__col product-delivery__col--merged"
  }, [_c('span', {
    staticClass: "title"
  }, [_vm._v("주의사항​")]), _c('ul', {
    staticClass: "ul"
  }, [_c('li', [_vm._v("미성년자가 물품을 구매하는 경우, 법정대리인이 동의하지 않으면 미성년자 본인 또는 법정대리인이 구매를 취소할 수 있습니다.​")]), _c('li', [_vm._v("안전검사 대상 공산품’을 구매하시는 경우 품질경영 및 공산품 안전관리법에 따른 안전검사 표시가 있는 제품인지 확인하시기 바랍니다.​")])])])])])]);

}]

export { render, staticRenderFns }
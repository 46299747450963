var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wrap wrap--sub"
  }, [_c('main-header'), _vm._m(0), _c('main-footer')], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main', {
    staticClass: "main",
    attrs: {
      "role": "main"
    }
  }, [_c('div', {
    staticClass: "collection-index-container"
  }, [_c('div', {
    staticClass: "page-head"
  }, [_c('div', {
    staticClass: "page-head__inner"
  }, [_c('h1', {
    staticClass: "page-head__title"
  }, [_vm._v("FAMILY SHOP")]), _c('ul', {
    staticClass: "breadcrumb"
  }, [_c('li', {
    staticClass: "breadcrumb__list"
  }, [_vm._v(" 항상 고객을 생각하는 마음으로 최선을 다하는 디아이디 ")])])])]), _c('div', {
    staticClass: "family-section1"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('h4', [_vm._v(" 자그마한 샘플북이 아닌 "), _c('strong', [_vm._v("직접보고 선택하는")])]), _c('h2', {
    staticClass: "color-point"
  }, [_vm._v("패밀리샵 논현 GRAND OPEN")]), _c('p', [_vm._v(" 학동역 도보 2분거리에 위치한 디아이디 패밀리샵 논현에서 600여개의 벽지랙으로 "), _c('br', {
    staticClass: "pc"
  }), _vm._v("실제 벽지에 바른 모습으로 확인하세요. ")]), _c('div', {
    staticClass: "family-box"
  }, [_c('p', [_vm._v(" “ 패밀리샵에는 "), _c('strong', {
    staticClass: "color-point"
  }, [_vm._v("3,000여종이 넘는 국내,수출벽지가 전시")]), _vm._v("되어 있으며 "), _c('br', {
    staticClass: "pc"
  }), _c('strong', {
    staticClass: "color-point"
  }, [_vm._v("실제 집처럼 크게 도배/연출")]), _vm._v("되어 있습니다. "), _c('br', {
    staticClass: "pc"
  }), _vm._v("또한 "), _c('strong', {
    staticClass: "color-point"
  }, [_vm._v("현장에서 바로 구매 가능")]), _vm._v("합니다. ” ")])]), _c('div', {
    staticClass: "box-list"
  }, [_c('h3', [_vm._v("디아이디 패밀리샵에서 제공하는 4가지 혜택!")]), _c('ul', [_c('li', [_c('div', {
    staticClass: "box-style"
  }, [_c('span', [_c('small', [_vm._v("600여개 벽지랙으로")]), _c('br'), _vm._v("실제 시공느낌 확인")])])]), _c('li', [_c('div', {
    staticClass: "box-style"
  }, [_c('span', [_c('small', [_vm._v("벽지 전문가의")]), _c('br'), _vm._v("컨설팅 제공")])])]), _c('li', [_c('div', {
    staticClass: "box-style"
  }, [_c('span', [_c('small', [_vm._v("국내시장에서 볼수없는")]), _c('br'), _vm._v("수출벽지 구매가능")])])]), _c('li', [_c('div', {
    staticClass: "box-style"
  }, [_c('span', [_c('small', [_vm._v("셀프시공존에서")]), _c('br'), _vm._v("실제 시공체험")])])])])])])]), _c('div', {
    staticClass: "family-section2"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('h3', [_c('strong', {
    staticClass: "color-point"
  }, [_vm._v("3000")]), _vm._v("여개의 다양한 벽지와 "), _c('strong', {
    staticClass: "color-point"
  }, [_vm._v("600")]), _vm._v("여개의 벽지랙으로 "), _c('br', {
    staticClass: "pc"
  }), _vm._v("다양한 종류의 벽지를 실제 우리집의 시공 모습을 미리 볼 수 있습니다. ")]), _c('div', {
    staticClass: "align-center"
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/family/family-section1-img.png",
      "alt": "이미지"
    }
  })])])]), _c('div', {
    staticClass: "family-section3"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "fs3-left"
  }, [_c('h2', {
    staticClass: "page-title30"
  }, [_vm._v(" 다양한 패턴의 수출벽지 "), _c('br', {
    staticClass: "pc"
  }), _vm._v("바로구매 가능! ")]), _c('p', {
    staticClass: "page-text22"
  }, [_vm._v(" 개별 취급점에서 구입할 수 없는 "), _c('br', {
    staticClass: "pc"
  }), _vm._v("다양한 패턴의 수입벽지를 "), _c('br', {
    staticClass: "pc"
  }), _vm._v("패밀리샵에서만 방문상담 후 "), _c('br', {
    staticClass: "pc"
  }), _vm._v("구매가능합니다. ")]), _c('img', {
    attrs: {
      "src": "/images/sub/family/family-section3-img.png",
      "alt": "이미지"
    }
  })]), _c('div', {
    staticClass: "fs3-right"
  }, [_c('div', {
    staticClass: "align-right"
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/family/family-section3-img2.png",
      "alt": "이미지"
    }
  })])]), _c('div', {
    staticClass: "img-block align-center"
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/family/family-section3-img3.png",
      "alt": "이미지"
    }
  })])])]), _c('div', {
    staticClass: "family-section4"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "fs4-left"
  }, [_c('h2', {
    staticClass: "page-title30"
  }, [_vm._v(" 벽지전문가의 "), _c('br', {
    staticClass: "pc"
  }), _vm._v("수준높은 컨설팅 제공 ")]), _c('p', {
    staticClass: "page-text22"
  }, [_vm._v(" 벽지전문가의 수준높은 벽지에 특화된 "), _c('br', {
    staticClass: "pc"
  }), _vm._v("전문상담으로 성공적인 인테리어의 "), _c('br', {
    staticClass: "pc"
  }), _vm._v("시작을 경험하실 수 있습니다. ")])]), _c('div', {
    staticClass: "fs4-right"
  }, [_c('div', {
    staticClass: "align-right"
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/family/family-section4-img.png",
      "alt": "이미지"
    }
  })])])])]), _c('div', {
    staticClass: "family-section8"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('h3', [_c('strong', {
    staticClass: "color-point"
  }, [_vm._v("다양한 매칭 서비스")]), _vm._v("로 내집에 어울리는 "), _c('strong', {
    staticClass: "color-point"
  }, [_vm._v("벽지선택 가이드")]), _vm._v("를 제시해 드립니다. ")]), _c('div', {
    staticClass: "align-center"
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/family/family-section9-img.png",
      "alt": "이미지"
    }
  })])])]), _c('div', {
    staticClass: "family-section5"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "fs5-left"
  }, [_c('h2', {
    staticClass: "page-title30"
  }, [_vm._v(" 셀프벽지 체험으로 "), _c('br', {
    staticClass: "pc"
  }), _vm._v("DIY 시공 스킬UP! ")]), _c('p', {
    staticClass: "page-text22"
  }, [_vm._v(" 셀프벽지 시공으로 나홀로 "), _c('br', {
    staticClass: "pc"
  }), _vm._v("벽지 시공에 도전! ")])]), _c('div', {
    staticClass: "fs5-right"
  }, [_c('div', {
    staticClass: "align-right"
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/family/family-section5-img.png",
      "alt": "이미지"
    }
  })])]), _c('div', {
    staticClass: "img-block align-center"
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/family/family-section5-img2.png",
      "alt": "이미지"
    }
  })])])]), _c('div', {
    staticClass: "family-section6"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "fs6-left"
  }, [_c('h2', {
    staticClass: "page-title30"
  }, [_vm._v(" 디아이디 패밀리샵은 "), _c('br', {
    staticClass: "pc"
  }), _vm._v("가까이에 있습니다. ")]), _c('p', {
    staticClass: "page-text22"
  }, [_vm._v(" 학동역에서 도보 2분 거리에 위치해 "), _c('br', {
    staticClass: "pc"
  }), _vm._v("교통이 편리합니다. ")]), _c('ul', [_c('li', [_c('strong', [_vm._v("학동역 7호선 ")]), _vm._v("도보 2분")])])]), _c('div', {
    staticClass: "fs6-right"
  }, [_c('div', {
    staticClass: "img-block align-right"
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/family/family-section6-img.png",
      "alt": "이미지"
    }
  })])])])]), _c('div', {
    staticClass: "family-section7"
  }, [_c('div', {
    staticClass: "container align-center"
  }, [_c('h2', {
    staticClass: "page-title30 color-grey7"
  }, [_vm._v("패밀리샵 문의")]), _c('a', {
    staticClass: "box-label",
    attrs: {
      "href": "/community/interior"
    }
  }, [_vm._v("벽지상담 및 문의")]), _c('p', {
    staticClass: "page-text16 weight-5 color-grey7"
  }, [_vm._v(" 02. 2038. 8803 ")]), _c('p', {
    staticClass: "page-text16 weight-5 color-grey9"
  }, [_vm._v(" 서울특별시 강남구 언주로129길 8 재정빌딩 1층 ")]), _c('p', {
    staticClass: "page-text16 weight-5 color-grey9"
  }, [_vm._v(" 10:00 - 19:00 공휴일, 일요일 휴무 ")])])])])]);

}]

export { render, staticRenderFns }
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wrap wrap--sub"
  }, [_c('main-header'), _c('main', {
    staticClass: "main",
    attrs: {
      "role": "main"
    }
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "collection-index-container"
  }, [_c('div', {
    staticClass: "page-head"
  }, [_c('div', {
    staticClass: "page-head__inner"
  }, [_c('h1', {
    staticClass: "page-head__title"
  }, [_vm._v(_vm._s(_vm.board.storeName))]), _c('ul', {
    staticClass: "breadcrumb"
  }, [_c('li', {
    staticClass: "breadcrumb__list"
  }, [_vm._v(_vm._s(_vm.board.summary))])])])]), _c('div', {
    staticClass: "shops-view-container"
  }, [_c('div', {
    staticClass: "shops-view-section"
  }, [_c('h2', {
    staticClass: "page-title26 page-title--line"
  }, [_vm._v("업체정보")]), _c('div', {
    staticClass: "shops-info"
  }, [_c('div', {
    staticClass: "shops-info-left"
  }, [_c('div', {
    staticClass: "info-list"
  }, [_c('ul', [_c('li', [_c('em', [_vm._v("지역")]), _vm._v(_vm._s(_vm.board.area))]), _c('li', [_c('em', [_vm._v("취급점명")]), _vm._v(_vm._s(_vm.board.storeName))]), _c('li', [_c('em', [_vm._v("주소")]), _vm._v(_vm._s(_vm.board.address1) + " " + _vm._s(_vm.board.address2))]), _c('li', [_c('em', [_vm._v("연락처")]), _vm._v(_vm._s(_vm.board.storePhone))]), _c('li', [_c('em', [_vm._v("이메일")]), _vm._v(_vm._s(_vm.board.storeEmail))]), _c('li', [_c('em', [_vm._v("취급컬렉션")]), _vm._l(_vm.board.collections, function (collection) {
    return _c('span', {
      staticClass: "label label-gray"
    }, [_vm._v(_vm._s(collection))]);
  })], 2)])])]), _vm._m(0)])]), (_vm.board.relativeProducts || []).filter(function (product) {
    return product._id;
  }).length ? _c('div', {
    staticClass: "shops-view-section",
    attrs: {
      "role": "row"
    }
  }, [_c('h2', {
    staticClass: "page-title26 page-title--line"
  }, [_vm._v("관련제품")]), _c('ul', {
    staticClass: "board-product-lists"
  }, _vm._l(_vm.board.relativeProducts.filter(function (product) {
    return product._id;
  }), function (product) {
    return _c('li', {
      staticClass: "board-product-list"
    }, [_c('a', {
      staticClass: "board-product",
      attrs: {
        "href": `/collection/${product.paperCollection.code}?code=${product.code}`
      }
    }, [_c('div', {
      staticClass: "board-product__thumb"
    }, [_c('span', {
      staticClass: "board-product__link"
    }, [_c('img', {
      staticClass: "board-product__thumbnail",
      attrs: {
        "src": product.thumb,
        "alt": ""
      }
    })])]), _c('div', {
      staticClass: "board-product__info"
    }, [_c('span', {
      staticClass: "board-product__text"
    }, [_vm._v(_vm._s(product.paperCollection.name))]), _c('span', {
      staticClass: "board-product__title"
    }, [_vm._v(_vm._s(product.name) + " " + _vm._s(product.nameEn))]), _c('span', {
      staticClass: "board-product__text"
    }, [_vm._v("[" + _vm._s(product.code) + "]")])])])]);
  }), 0)]) : _vm._e(), _c('div', {
    staticClass: "shops-view-section"
  }, [_c('h2', {
    staticClass: "page-title26 page-title--line"
  }, [_vm._v("시공 포트폴리오")]), _c('div', {
    staticClass: "center-board"
  }, [_c('board-photo-basic3-list', {
    attrs: {
      "boards": _vm.board.portfolios
    }
  })], 1)])])])])]), _c('main-footer')], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "shops-info-right"
  }, [_c('div', {
    staticClass: "info-map"
  }, [_c('div', {
    staticStyle: {
      "width": "100%",
      "height": "400px"
    },
    attrs: {
      "id": "map"
    }
  })])]);

}]

export { render, staticRenderFns }
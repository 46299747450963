<template>
    <div class="board-container">
        <!-- <ul class="tab-list tab-list--tooth">
            <li class="on"><a class="button" role="link">진행중 이벤트</a></li>
            <li><a class="button" role="link">벽지종류</a></li>
        </ul> -->
        <div class="board-photo board-photo--basic3">
            <ul>
                <li v-for="board in boards">
                    <div class="photo-wrap">
                        <div class="photo-header">
                            <div class="photo-img">
                                <a role="link" :style="{ backgroundImage: `url('${board.image}')` }"></a>
                            </div>
                        </div>
                        <div class="photo-body">
                            <a role="link">
                                <p class="photo-category"><span>{{ board.spot }}</span><span>{{ board.size }}</span><span>{{ board.style }}</span></p>
                                <h2 class="photo-title">{{ board.name }}</h2>
                            </a>
                        </div>
                    </div>
                </li>

            </ul>
        </div>
        <!-- <div class="pagination pagination--block">
            <a class="pagination__backward" href=""></a>
            <a class="pagination__prev" href=""></a>
            <span class="pagination-lists">
                <a class="current">1</a>
                <a class="" href="">2</a>
            </span>
            <a class="pagination__next" href=""></a>
            <a class="pagination__forward" href=""></a>
        </div> -->
        <!-- <div class="board-buttons">
            <a class="button button--border" href=""><span>리스트</span></a>
            <a class="button button--primary" href=""><span>글쓰기</span></a>
        </div> -->
    </div>
</template>

<script>
export default{
    props: {
        boards: { type: Array, default: [] }
    },
}
</script>

<template id="modal-template">
    <transition v-if="show && setting" name="modal" appear>
        <div class="modal-container">
            <div class="modal-wrapper">
                <div class="modal modal--7">
                    <div class="modal__head modal__head--no-border-bottom">
                        <h1 class="modal__title">무통장 입금</h1>
                        <button class="modal__close" type="button" @click="close">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                <path d="M0.222,1.636 L1.636,0.221 L15.778,14.363 L14.364,15.778 L0.222,1.636 Z"/>
                                <path d="M14.364,0.221 L15.778,1.636 L1.636,15.778 L0.222,14.363 L14.364,0.221 Z"/>
                            </svg>
                        </button>
                    </div>
                    <div class="modal__body">
                        <div class="modal-order-form">
                            <div class="row">
                                <h2 class="title">입금하실 계좌정보</h2>
                                <div role="table" class="table">
                                    <div role="rowgroup">
                                        <div role="row">
                                            <div role="columnheader">입금하실 계좌</div>
                                            <div role="cell">{{ banks.find((bank) => bank.code == setting.shop.accountBankCode ).name }}</div>
                                        </div>
                                        <div role="row">
                                            <div role="columnheader">예금주명</div>
                                            <div role="cell">{{ setting.shop.accountHolder }}</div>
                                        </div>
                                        <div role="row">
                                            <div role="columnheader">계좌번호</div>
                                            <div role="cell">{{ setting.shop.accountNumber }}</div>
                                        </div>
                                        <div role="row">
                                            <div role="columnheader">입금 금액</div>
                                            <div role="cell"><b class="color-red">{{ totalPrice.format() }}원</b></div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <p class="caption color-red">
                                    ※위의 계좌로 무통장 입금 신청 후 <br class="mo"><b>5시간 내에 정확한 금액을 입금 진행</b>하셔야 합니다.
                                    <br>
                                    5시간 이내 입금되지 않을시 주문이 취소됩니다.
                                </p> -->
                            </div>
                            <div class="row">
                                <h2 class="title">입금 계좌정보</h2>
                                <div role="table" class="table">
                                    <div role="rowgroup">
                                        <div role="row">
                                            <div role="columnheader">입금은행</div>
                                            <div role="cell">
                                                <select v-model="order.account.accountBankCode" class="select" name="">
                                                    <option v-for="bank in banks" :value="bank.code">{{ bank.name }}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div role="row">
                                            <div role="columnheader">예금주명</div>
                                            <div role="cell">
                                                <input v-model="order.account.accountHolder" class="input" type="text" name="" value="">
                                            </div>
                                        </div>
                                        <div role="row">
                                            <div role="columnheader">계좌번호</div>
                                            <div role="cell">
                                                <input v-model="order.account.accountNumber" class="input input--wide" type="number" name="" value="">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="add-form-box">
                                    <div class="add-form-box__head">
                                        <span class="add-form-box__title">현금영수증 신청하기</span>
                                        <div class="radio-wrap">
                                            <label class="radio radio--check">
                                                <input v-model="order.cashRecipt.enabled" class="input" type="radio" name="radio" :value="false">
                                                <i class="icon"></i>
                                                <span class="text">신청안함</span>
                                            </label>
                                            <label class="radio radio--check">
                                                <input v-model="order.cashRecipt.enabled" class="input" type="radio" name="radio" :value="true">
                                                <i class="icon"></i>
                                                <span class="text">현금영수증 신청하기</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div v-if="order.cashRecipt.enabled" class="add-form-box__body">
                                        <span class="input-flex-title">발급받을 전화번호 또는 사업자 번호</span>
                                        <div class="input-flex">
                                            <input v-model="order.cashRecipt.phone" class="input" type="text" name="" value="">
                                            <p>
                                                “-” 없이 숫자만 입력 부탁드립니다. <br>
                                                010-1234-1234 는 01012341234로 입력
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal__foot">
                        <div class="modal__buttons modal__buttons--narrow">
                            <button class="button button--border-primary" type="button" @click="close"><span>취소하기</span></button>
                            <button class="button button--primary" type="button" @click="pay"><span>결제하기</span></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import banks from "../../../plugins/shop-default-banks.json";
export default{
    props: {
        value: { type: Object, default: null },
        totalPrice: { type: Number, default: 0 }
    },
    data: () => {
        return {
            show: false,
            order: null,
            setting: null,
            banks
        };
    },
    mounted(){
        this.order = this.value;
        this.init();
    },
    methods: {
        async init(){
            try{
                await this.getSetting();
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
                else alert(error.message);
            }
        },
        open(){
            this.show = true;
            document.querySelector('html').setAttribute('data-modal', 'opened');
        },
        close(){
            this.show = false;
            document.querySelector('html').removeAttribute('data-modal');
        },
        pay(){
            this.$emit('pay');
            this.close();
        },
        getSetting(){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.get('/api/v1/setting');

                    this.setting = res.data.setting;

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        }
    },
    watch: {
        value(){
            this.order = this.value;
        }
    }
}
</script>

<template>
	<div class="wrap wrap--sub">
		<!-- 헤더 -->
		<main-header></main-header>

		<!-- 메인 -->
		<main class="main" role="main">
            <div class="container">
                <div class="collection-index-container">
                    <div class="page-head">
                        <div class="page-head__inner">
                            <h1 class="page-head__title">LISTS ALL</h1>
                            <!-- <ul class="breadcrumb">
                                <li class="breadcrumb__list">Home</li>
                                <li class="breadcrumb__list">컬렉션</li>
                                <li class="breadcrumb__list">국내</li>
                                <li class="breadcrumb__list">디앤디 컬렉션 인덱스</li>
                            </ul> -->
                        </div>
                    </div>

                    <div class="lists-all-container">
                        <div class="lists-all-header">
                            <div role="table" class="table-style">
                                <div role="rowgroup">
                                    <div role="row">
                                        <div role="columnheader">컬 렉 션</div>
                                        <div role="cell">
											<label v-for="collection in collections" class="button button--toggle" :class="{ 'active': filter._collections.includes(collection._id) }" ><input v-model="filter._collections" type="checkbox" name="collection" :value="collection._id">{{ collection.name }}</label>
                                        </div>
                                    </div>
                                </div>
                                <div role="rowgroup">
                                    <div role="row">
                                        <div role="columnheader">디자인</div>
                                        <div role="cell">
                                            <label v-for="design in ['스톤','플라워','기하무늬','스트라이프','디마스크','솔리드','내츄럴','패브릭','벽돌','기타']" class="button button--toggle" :class="{ 'active': filter.designs.includes(design) }"><input v-model="filter.designs" type="checkbox" name="design" :value="design">{{ design }}</label>
                                        </div>
                                    </div>
                                </div>
                                <div role="rowgroup">
                                    <div role="row">
                                        <div role="columnheader">용도</div>
                                        <div role="cell">
											<label v-for="usage in ['포인트','아동','천정','범용']" class="button button--toggle" :class="{ 'active': filter.usages.includes(usage) }"><input v-model="filter.usages" type="checkbox" name="usage" :value="usage">{{ usage }}</label>
                                            <!-- <button class="button button--toggle active">포인트</button>
                                            <button class="button button--toggle">아동</button>
                                            <button class="button button--toggle active">천장</button>
                                            <button class="button button--toggle">범용</button> -->
                                        </div>
                                    </div>
                                </div>
								<div role="rowgroup">
									<div role="row">
										<div role="columnheader">스타일</div>
										<div role="cell">
											<label v-for="style in ['플레인','내추럴','지오매트릭','플라워','키즈']" class="button button--toggle" :class="{ 'active': filter.styles.includes(style) }"><input v-model="filter.styles" type="checkbox" name="style" :value="style">{{ style }}</label>
											<!-- <button class="button button--toggle active">플레인</button>
											<button class="button button--toggle">내추럴</button>
											<button class="button button--toggle active">지오매트릭</button>
											<button class="button button--toggle">플라워</button>
											<button class="button button--toggle">키즈</button> -->
										</div>
									</div>
								</div>
								<div role="rowgroup">
									<div role="row">
										<div role="columnheader">단가</div>
										<div role="cell">
											<label v-for="unitPrice in ['저가','중가','고가']" class="button button--toggle" :class="{ 'active': filter.unitPrices.includes(unitPrice) }"><input v-model="filter.unitPrices" type="checkbox" name="unitPrice" :value="unitPrice">{{ unitPrice }}</label>
											<!-- <button class="button button--toggle active">저가</button>
											<button class="button button--toggle">중가</button>
											<button class="button button--toggle">고가</button> -->
										</div>
									</div>
								</div>

								<div role="rowgroup">
									<div role="row">
										<div role="columnheader">종류</div>
										<div role="cell">
											<label v-for="kind in ['내수','수출']" class="button button--toggle" :class="{ 'active': filter.kinds.includes(kind) }"><input v-model="filter.kinds" type="checkbox" name="kind" :value="kind">{{ kind }}</label>
											<!-- <button class="button button--toggle active">내수</button>
											<button class="button button--toggle">수출</button> -->
										</div>
									</div>
								</div>

								<div role="rowgroup">
									<div role="row">
										<div role="columnheader">타입</div>
										<div role="cell">
											<label v-for="type in ['실크','합지']" class="button button--toggle" :class="{ 'active': filter.types.includes(type) }"><input v-model="filter.types" type="checkbox" name="type" :value="type">{{ type }}</label>
											<!-- <button class="button button--toggle active">실크</button>
											<button class="button button--toggle">합지</button> -->
										</div>
									</div>
								</div>

                                <div role="rowgroup">
                                    <div role="row">
                                        <div role="columnheader">컬 러</div>
                                        <div role="cell">
											<label v-for="color in ['화이트/베이지','옐로우','블루/그린','바이올렛/퍼플','레드/와인','핑크','브라운','블랙/그레이','메탈','기타']" class="button button--color" :class="{ 'active': filter.colors.includes(color) }"><input v-model="filter.colors" type="checkbox" name="color" :value="color">
												<img :src="`/images/sub/colors/${color.replace(/\//g ,'_')}.jpg`">
											</label>
											<div>
												<span v-if="filter.colors.includes('화이트/베이지')">화이트/베이지</span>
												<span v-if="filter.colors.includes('옐로우')"> 옐로우</span>
												<span v-if="filter.colors.includes('블루/그린')"> 블루/그린</span>
												<span v-if="filter.colors.includes('바이올렛/퍼플')"> 바이올렛/퍼플</span>
												<span v-if="filter.colors.includes('레드/와인')"> 레드/와인</span>
												<span v-if="filter.colors.includes('핑크')"> 핑크</span>
												<span v-if="filter.colors.includes('브라운')"> 브라운</span>
												<span v-if="filter.colors.includes('블랙/그레이')"> 블랙/그레이</span>
												<span v-if="filter.colors.includes('메탈')"> 메탈</span>
												<span v-if="filter.colors.includes('기타')"> 기타</span>
											</div>
											<!-- <div v-if="filter.colors.includes(color)">{{ color }}</div> -->
                                            <!-- <button class="button button--color bg-white"></button>
                                            <button class="button button--color bg-yellow"></button>
                                            <button class="button button--color bg-green"></button>
                                            <button class="button button--color bg-blue"></button>
                                            <button class="button button--color bg-purple"></button>
                                            <button class="button button--color bg-red"></button>
                                            <button class="button button--color bg-pink"></button>
                                            <button class="button button--color bg-brown"></button>
                                            <button class="button button--color bg-dark"></button>
                                            <button class="button button--color bg-gradation"></button> -->
                                        </div>
                                    </div>
                                </div>
                                <div role="rowgroup">
                                    <div role="row">
                                        <div role="columnheader">제 품 명</div>
                                        <div role="cell">
                                            <div class="form-input-btn">
                                                <input v-model="filter.searchValue" type="text" placeholder="제품명 또는 제품넘버를 입력해주세요" class="input">
                                                <button class="button button--square button--bluegrey" @click="search">검색</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="board-container">
                        <p class="board-caption">총 <span class="color-point">{{ summary.totalCount.format() }}개</span>가 검색되었습니다.</p>
                        <div class="board-photo board-photo--basic4">
                            <ul>
                                <li v-for="paper in papers">
                                    <div class="photo-wrap">
                                        <div class="photo-header">
                                            <div class="photo-img">
                                                <a role="link" :href="`/collection/${paper.paperCollection.code}?code=${paper.code}`" :style="{ 'backgroundImage': `url('${paper.thumb}')`}"></a>
                                            </div>
                                        </div>
                                        <div class="photo-body">
                                            <a role="link">
                                                <h2 class="photo-title">{{ paper.code }}</h2>
                                                <p class="photo-text">{{ paper.name }}</p>
                                            </a>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <pagination-component v-model="page" :count="pageCount" @input="search"></pagination-component>
                    </div>
                    <!-- <img src="/images/sub/collection/collection_all.png" alt=""> -->
                </div>
            </div>
		</main>

		<!-- 푸터 -->
		<main-footer></main-footer>
	</div>
</template>

<script>
import MainHeader from '@/components/client/inc/main-header.vue';
import MainFooter from '@/components/client/inc/main-footer.vue';

import PaginationComponent from "@/components/client/pagination-component.vue";

export default{
	components: {
		MainHeader,
		MainFooter,
		PaginationComponent
	},
	data: () => {
		return {
			filter: {
				_collections: [],
				designs: [],
				usages: [],
				styles: [],
				unitPrices: [],
				kinds: [],
				types: [],
				colors: [],
				searchKey: "name|code",
				searchValue: null,
			},

			collections: [],

			page: 1,
			pageCount: 0,
			limit: 12,

			summary: { totalCount: 0 },
			papers: []

		};
	},
	created() {
	},
	mounted() {
		this.init();
	},
	methods: {
		async init(){
			try{
				await this.getCollections();
				this.filter.searchValue = this.$route.query.searchValue;
					await this.search();
				if(this.$route.query.searchValue){
					this.filter.searchValue = this.$route.query.searchValue;
					this.search();
				}
			}
			catch(error){
				console.error(error);
				if(error.response) alert(error.response.data.message);
				else alert(error.message);
			}
		},
		async search(){
			try{
				await this.getPapers();
			}
			catch(error){
				console.error(error);
				if(error.response) alert(error.response.data.message);
				else alert(error.message);
			}
		},
		getCollections(){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.get("/api/v1/shop/categories", {
						params: {
							code: "collection"
						}
					});

                    this.collections = res.data.categories[0].children;

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        },
		getPapers(){
			return new Promise(async (resolve, reject) => {
				try{
					console.log({ filter: this.filter })
					var res = await this.$http.get("/api/v1/shop/products", {
						headers: {
							skip: (this.page - 1) * this.limit,
							limit: this.limit
						},
						params: this.filter
					});

					this.papers = res.data.products;
					this.summary = res.data.summary;
					this.pageCount = Math.ceil(this.summary.totalCount / this.limit);

					resolve();
				}
				catch(error){
					reject(error);
				}
			});
		},
	}
}
</script>

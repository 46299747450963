var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wrap wrap--products"
  }, [_c('main-header'), _c('main', {
    staticClass: "main",
    attrs: {
      "role": "main"
    }
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "product-order-container"
  }, [_vm._m(0), _c('div', {
    staticClass: "purchase-title"
  }, [_vm.success ? _c('div', {
    staticClass: "img"
  }, [_c('img', {
    attrs: {
      "src": "/images/icon/check-graphic-round.png",
      "alt": ""
    }
  })]) : _c('div', {
    staticClass: "img"
  }, [_c('img', {
    attrs: {
      "src": "/images/icon/caution-graphic.png",
      "alt": ""
    }
  })]), _vm.success ? _c('h2', {
    staticClass: "purchase-title__text"
  }, [_vm._v("샘플 신청이 완료 되었습니다.")]) : _c('h2', {
    staticClass: "purchase-title__text"
  }, [_vm._v("샘플 신청에 실패했습니다.")]), _vm.success ? _c('p', {
    staticClass: "purchase-title__pr"
  }, [_vm._v("이용해 주셔서 감사합니다.")]) : _c('p', {
    staticClass: "purchase-title__pr"
  }, [_vm._v(_vm._s(_vm.message))])]), _c('div', {
    staticClass: "order-buttons"
  }, [_vm.success ? _c('a', {
    staticClass: "button button--primary",
    attrs: {
      "href": "/mypage/order-list"
    }
  }, [_c('span', [_vm._v("신청내역 확인")])]) : _vm._e(), _vm._m(1)])])])]), _c('main-footer')], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "order-title"
  }, [_c('h1', {
    staticClass: "order-title__text"
  }, [_vm._v("신청결과")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    staticClass: "button button--primary",
    attrs: {
      "e-else": "",
      "href": "/"
    }
  }, [_c('span', [_vm._v("확인")])]);

}]

export { render, staticRenderFns }
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "board-container"
  }, [_c('div', {
    staticClass: "board-photo board-photo--basic3"
  }, [_c('ul', _vm._l(_vm.boards, function (board) {
    return _c('li', [_c('div', {
      staticClass: "photo-wrap"
    }, [_c('div', {
      staticClass: "photo-header"
    }, [_c('div', {
      staticClass: "photo-img"
    }, [_c('a', {
      style: {
        backgroundImage: `url('${board.image}')`
      },
      attrs: {
        "role": "link"
      }
    })])]), _c('div', {
      staticClass: "photo-body"
    }, [_c('a', {
      attrs: {
        "role": "link"
      }
    }, [_c('p', {
      staticClass: "photo-category"
    }, [_c('span', [_vm._v(_vm._s(board.spot))]), _c('span', [_vm._v(_vm._s(board.size))]), _c('span', [_vm._v(_vm._s(board.style))])]), _c('h2', {
      staticClass: "photo-title"
    }, [_vm._v(_vm._s(board.name))])])])])]);
  }), 0)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }
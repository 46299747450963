<template>
	<div class="wrap wrap--products">
		<!-- 헤더 -->
		<main-header></main-header>

		<!-- 메인 -->
		<main class="main" role="main">
            <div class="container">
                <div class="product-order-container">
					<!-- 페이지 타이틀 -->
                    <div class="order-title">
                        <h1 class="order-title__text">신청결과</h1>
                    </div>
                    <div class="purchase-title">
                        <div v-if="success" class="img"><img src="/images/icon/check-graphic-round.png" alt=""></div>
						<div v-else class="img"><img src="/images/icon/caution-graphic.png" alt=""></div>
                        <h2 v-if="success" class="purchase-title__text">샘플 신청이 완료 되었습니다.</h2>
						<h2 v-else class="purchase-title__text">샘플 신청에 실패했습니다.</h2>
                        <p v-if="success" class="purchase-title__pr">이용해 주셔서 감사합니다.</p>
						<p v-else class="purchase-title__pr">{{ message }}</p>
                    </div>
                    <!-- 결제 가격 요약 -->
                    <!-- 내용 숨김처리: 2020-11-12
					<div v-if="order" class="purchase-overview">
                        <div class="purchase-overview-table">
                            <div class="tr">
                                <div class="th"><div>결제 방법</div></div>
                                <div class="td">신용카드</div>
                            </div>
                            <div class="tr">
                                <div class="th"><div>상품금액</div></div>
                                <div class="td">{{ order.productPrice.format() }} 원</div>
                            </div>
                            <div class="tr">
                                <div class="th"><div>배송비</div></div>
                                <div class="td">{{ order.deliveryPrice.format() }} 원</div>
                            </div>
                            <div class="tr">
                                <div class="th"><div>쿠폰 및 적립금 할인</div></div>
                                <div class="td">- {{ order.discountPrice.format() }} 원</div>
                            </div>
                            <div class="tr">
                                <div class="th"><div>총 결제금액</div></div>
                                <div class="td"><b class="num">{{ order.totalPrice.format() }}</b> 원</div>
                            </div>
                        </div>
                    </div> -->
                    <!-- 배송지 정보 -->
                    <!-- 내용 숨김처리: 2020-11-12
					<div v-if="order" class="purchase-information">
                        <div class="purchase-information-caption">
                            <span class="title">배송지 정보</span>
                        </div>
                        <div class="purchase-information-table">
                            <div class="tr">
                                <div class="th"><div>이름</div></div>
                                <div class="td">{{ order.receiver.name }}</div>
                            </div>
                            <div class="tr">
                                <div class="th"><div>주소</div></div>
                                <div class="td">{{ order.receiver.address1 }} {{ order.receiver.address2}}</div>
                            </div>
                            <div class="tr">
                                <div class="th"><div>연락처</div></div>
                                <div class="td">{{ order.receiver.phone }}</div>
                            </div>
                            <div class="tr">
                                <div class="th"><div>배송요청사항</div></div>
                                <div class="td">{{ order.requestMessage }}</div>
                            </div>
                        </div>
                    </div> -->
                    <!-- 주문 상품 리스트 -->
                    <!-- 내용 숨김처리: 2020-11-12
					<div v-if="order" class="purchase-item-list">
                        <span class="purchase-item-list__title">주문상품</span>
                        <div class="purchase-item-table">
                            <div v-for="map in cartsMap" class="tr">
                                <div class="td">
                                    <div class="purchase-item">
                                        <div class="purchase-item__thumb">
                                            <a class="purchase-item__thumb-link" href="/shop/products/view">
                                                <img class="purchase-item__thumbnail" :src="map.product.thumb" alt="">
                                            </a>
                                        </div>
                                        <div class="purchase-item__info">
                                            <span class="purchase-item__title" href=""><a class="link" href="/shop/products/view">{{ map.product.name }}​</a></span>

                                            <div class="purchase-item__option">
                                                <div v-for="cart in map.carts" class="purchase-item__option-list">
                                                    <span class="purchase-item__option-title">{{ cart.name }}<span class="purchase-item__option-">, {{ cart.amount.format() }}개</span></span>
                                                    <div class="purchase-item__option-bottom">
                                                        <div class="purchase-item__option-price">
                                                            <span class="price"><em class="num">{{ (cart.salePrice * cart.amount).format() }}</em>원</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
					<!-- 페이지 버튼 -->
                    <div class="order-buttons">
                        <a v-if="success" class="button button--primary" href="/mypage/order-list"><span>신청내역 확인</span></a>
						<a e-else class="button button--primary" href="/"><span>확인</span></a>
                    </div>
                </div>
            </div>
		</main>

		<!-- 푸터 -->
		<main-footer></main-footer>
	</div>
</template>

<script>
import MainHeader from "../../../components/client/inc/main-header.vue";
import MainFooter from "../../../components/client/inc/main-footer.vue";

export default{
	components: {
		MainHeader,
		MainFooter
	},
	data: () => {
		return {
			order: null,
			success: false,
			message: null
		}
	},
	created() {
	},
	mounted() {
		this.init();
	},
	methods : {
		async init() {
			try{
				await this.verifyOrder();
				await this.getOrder();

				this.success = true;
			}
			catch(error){
				console.error(error);
				if(error.response) this.message = error.response.data.message;
			}
		},

		verifyOrder(){
			return new Promise(async (resolve, reject) => {
				try{
					var res = await this.$http.get('/api/v1/shop/orders/validate', {
						params: this.$route.query
					});

					this.order = res.data.order;

					resolve();
				}
				catch(error){
					reject(error);
				}
			});
		},

		getOrder(){
			return new Promise(async (resolve, reject) => {
				try{
					var res = await this.$http.get(`/api/v1/me/orders/${this.order._id}`);

					this.order = res.data.order;

					resolve();
				}
				catch(error){
					reject(error);
				}
			});
		}
	},
	computed: {
		cartsMap(){
			var map = new Map();
			if(this.order){
				for(var i in this.order.purchases){
					var cart = this.order.purchases[i];
					var value = map.get(cart._product);
					if(value) value.carts.push(cart);
					else value = { product: cart.product, carts: [cart] };
					map.set(cart._product, value);
				}
			}
			return Array.from(map.values());
		}
	}
}
</script>

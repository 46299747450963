<template>
	<div v-if="ready" class="wrap wrap--products">
		<!-- 헤더 -->
		<main-header></main-header>

		<!-- 메인 -->
		<main class="main" role="main">
            <div class="container">
                <div class="product-order-container">
					<!-- 페이지 타이틀 -->
                    <div class="order-title">
                        <!-- <h1 class="order-title__text">주문/결제하기</h1> -->
						<h1 class="order-title__text">샘플 신청하기</h1>
                    </div>
					<!-- 장바구니 -->
                    <div class="cart-list-container">
                        <!-- 장바구니 리스트 테이블 -->
                        <div class="cart-list-table">
                            <div class="tr">
                                <div class="th">상품정보</div>
                            </div>
							<div v-for="(map, index) in cartsMap" class="tr">
                                <div class="td">
                                    <div class="basket">
										<!-- 상품박스 -->
										<div class="basket__box">
											<div class="basket__thumb">
												<img height="160px" width="160px" :src="map.product?.thumb" alt="">
												<!-- <a class="basket__thumb-link" onclick="return false;">
													<img class="basket__thumbnail" :src="map.product?.thumb" alt="">
												</a> -->
											</div>
											<div class="basket__info">
												<span class="basket__title" href=""><a class="link" onclick="return false;">[{{ map.product?.code }}] {{ map.product?.name }}</a></span>
                                                <!-- 상품 옵션 리스트 -->
												<div v-show="false" class="basket__option">
                                                    <div v-for="cart in map.carts" class="basket__option-list">
														<span class="basket__option-title">{{ cart.name }}<span class="basket__option-">, {{ cart.amount?.format() }}개</span></span>
														<div class="basket__option-bottom">
															<div class="basket__option-price">
																<span class="price">
																	<em class="num">{{ cart.salePrice?.format() }}원</em>
																	<span v-if="cart.product.discountPrice" class="percent">{{ cart.product?.discountRate }}%</span>
																</span>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 가격 요약 -->
                    <div v-if="accessToken && false" class="order-overview">
                        <!-- 할인 내역 -->
                        <section class="order-overview__saving">
                            <section class="order-saving-container">
                                <section class="order-saving">
                                    <span class="order-saving__title">쿠폰 사용</span>
                                    <div class="order-saving__contents">
                                        <div class="order-saving__block">
                                            <span class="order-saving__subtitle">상품 / 주문쿠폰</span>
                                            <div class="order-saving__form">
                                                <div class="input input--red"><input type="number" onkeydown="return event.keyCode !== 69" :value="couponPrice"><span class="currency-unit">원</span></div>
                                                <button class="button button--mouse" type="button" @click="openModalUseCoupon"><span>쿠폰사용</span></button>
                                                <span class="left-text">사용가능 쿠폰 : {{ $refs['modal-use-coupon'] ? $refs['modal-use-coupon'].coupons.length : 0 }}장</span>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section class="order-saving">
                                    <span class="order-saving__title">적립금 사용</span>
                                    <div class="order-saving__contents">
                                        <div class="order-saving__block">
                                            <span class="order-saving__subtitle">보유 적립금액</span>
                                            <div class="order-saving__form">
                                                <div class="input"><input type="number" onkeydown="return event.keyCode !== 69" :value="me.point"><span class="currency-unit">원</span></div>
                                            </div>
                                        </div>
                                        <div class="order-saving__block">
                                            <span class="order-saving__subtitle">사용할 적립금</span>
                                            <div class="order-saving__form">
                                                <div class="input input--red"><input v-model="order.point" type="number" onkeydown="return event.keyCode !== 69" @input="checkPoint"><span class="currency-unit">원</span></div>
                                                <button class="button button--mouse" type="button"><span>전액사용</span></button>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </section>
                        </section>
                        <!-- 총 금액 -->
                        <section class="order-overview__summary">
                            <section class="order-summary-container">
                                <section class="order-summary">
                                    <div class="col">
                                        <span class="title">상품금액</span>
                                        <span class="price"><em class="num">{{ productPrice.format() }}</em> 원</span>
                                    </div>
                                    <div class="symbol symbol--plus"></div>
                                    <div class="col">
                                        <span class="title">배송비</span>
                                        <span class="price"><em class="num">{{ (deliveryPrice + islandPrice).format() }}</em> 원</span>
                                    </div>
                                    <div class="symbol symbol--minus"></div>
                                    <div class="col">
                                        <span class="title">할인금액합계</span>
                                        <span class="price price-red"><em class="num">{{ (discountPrice + couponPrice + order.point).format() }}</em> 원</span>
                                    </div>
                                </section>
                                <section class="order-summary order-summary--total">
                                    <div class="col">
                                        <span class="title">최종결제금액</span>
                                        <span class="price"><em class="num">{{ totalPrice.format() }}</em> 원</span>
                                    </div>
                                </section>
                            </section>
                        </section>
                    </div>
                    <!-- 주문 작성 폼 -->
                    <div class="order-information">
						<section class="order-form">
                            <div class="order-form-caption">
                                <span class="title">배송지 정보 입력</span>
                                <span class="strong">*는 필수입력항목입니다.</span>
                            </div>
                            <div class="order-form-table">
								<div v-if="me && me.shippingDestinations.length" class="tr">
                                    <div class="th"><div>배송지 선택</div></div>
                                    <div class="td">
										<div>
											<span v-if="basicShippingDestination" class="label-block">
												<label class="radio">
													<input type="radio" class="input" name="shippingDestination" @input="updateReceiver(basicShippingDestination)" :checked="order.receiver._id == basicShippingDestination._id">
													<i class="icon"></i>
													<span class="text">기본배송지</span>
												</label>
											</span>
											<span class="label-block">
												<label class="radio">
													<input type="radio" class="input" name="shippingDestination" @input="updateReceiver(defaultShippingDestination)" :checked="order.receiver._id == null">
													<i class="icon"></i>
													<span class="text">신규배송지</span>
												</label>
											</span>
										</div>
										<div>
											<p>최근 배송지</p>
											<span v-for="shippingDestination in me.shippingDestinations.filter((shippingDestination) => !shippingDestination.basic)" class="label-block">
												<label class="radio">
													<input type="radio" name="shippingDestination" @input="updateReceiver(shippingDestination)" class="input" :checked="order.receiver._id == shippingDestination._id">
													<i class="icon"></i>
													<span class="text">{{ shippingDestination.title || shippingDestination.name }}</span>
												</label>
												<button @click="removeShippingDestination(shippingDestination)" class="delete"><span class="blind">배송지 삭제</span></button>
											</span>
										</div>
                                    </div>
                                </div>
								<div class="tr">
                                    <div class="th"><div>수령인 <span class="strong">*</span></div></div>
                                    <div class="td">
                                        <input v-model="order.receiver.name" class="input" type="text" name="" value="">
                                    </div>
                                </div>
								<div v-if="me && !order.receiver._id" class="tr">
									<div class="th"><div>배송지 명</div></div>
									<div class="td">
										<div class="block">
											<input v-model="order.receiver.title" class="input" type="text" name="" value="">
											<label class="check">
												<input v-model="order.receiver.basic" class="input" type="checkbox">
												<i class="icon"></i>
												<span class="text">기본배송지로 선택</span>
											</label>
										</div>
									</div>
								</div>
                                <div class="tr">
                                    <div class="th"><div>배송지 주소 <span class="strong">*</span></div></div>
                                    <div class="td">
										<!-- 주소 폼 -->
                                        <div class="form-address">
                                            <div class="row">
                                                <input v-model="order.receiver.postcode" class="input input--small" type="text" readonly @click="findAddress(order.receiver)">
                                                <button class="button button--border-primary" type="button" @click="findAddress(order.receiver)"><span>주소찾기</span></button>
                                            </div>
                                            <div class="row">
                                                <input v-model="order.receiver.address1" class="input" type="text" readonly @click="findAddress(order.receiver)">
                                                <input v-model="order.receiver.address2" class="input" type="text" placeholder="상세주소를 입력하세요.">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tr">
                                    <div class="th"><div>연락처 <span class="strong">*</span></div></div>
                                    <div class="td">
										<!-- 연락처 폼 -->
										<div class="form-tel">
											<select v-model="receiverPhone1" class="select"><option value="010">010</option></select>
											<span class="text"></span>
											<input v-model="receiverPhone2" class="input" type="tel" @input="receiverPhone2 = receiverPhone2.replace(/[^0-9]/,'')">
											<span class="text"></span>
											<input v-model="receiverPhone3" class="input" type="tel" @input="receiverPhone3 = receiverPhone3.replace(/[^0-9]/,'')">
										</div>
                                    </div>
                                </div>
								<div class="tr">
                                    <div class="th"><div>이메일 <span class="strong">*</span></div></div>
                                    <div class="td">
										<!-- 이메일 폼 -->
										<div class="form-email">
											<input v-model="receiverEmail1" class="input" type="text" @input="receiverEmail1 = receiverEmail1.replace(/[^0-9a-zA-Z\-.]/,'')">
											<span class="text">@</span>
											<input v-model="receiverEmail2" class="input" type="text" @input="receiverEmail2 = receiverEmail2.replace(/[^0-9a-zA-Z\-.]/,'')">
											<select class="select" name=""><option value="">직접입력</option></select>
										</div>
                                    </div>
                                </div>
								<div class="tr">
                                    <div class="th"><div>배송요청사항</div></div>
                                    <div class="td">
										<input v-model="order.requestMessage" class="input input--full" type="text" name="" value="" maxlength="50" placeholder="50자이내">
                                    </div>
                                </div>
                            </div>
                        </section>
						<section v-show="false" class="order-form">
							<div class="order-form-caption">
								<span class="title">결제 방법 선택</span>
							</div>
							<div class="order-form-block">
								<div class="row">
									<div class="radio-block-container">
										<label class="radio-block"><input v-model="order.paymentMethod" class="input" type="radio" name="payment_method" value="card"><span class="button button--border-primary">신용카드</span></label>
										<label class="radio-block"><input v-model="order.paymentMethod" class="input" type="radio" name="payment_method" value="account"><span class="button button--border-primary">무통장 입금</span></label>
										<label class="radio-block"><input v-model="order.paymentMethod" class="input" type="radio" name="payment_method" value="kakaopay"><span class="button button--border-primary">카카오페이</span></label>
									</div>
								</div>
							</div>
						</section>
                    </div>
					<!-- 페이지 버튼 -->
                    <div class="order-buttons">
                        <a class="button button--border-primary" href="/"><span>취소하기</span></a>
                        <!-- <a class="button button--primary" @click="pay"><span>결제하기</span></a> -->
						<a class="button button--primary" @click="pay"><span>샘플 신청하기</span></a>
                    </div>
                </div>
            </div>

			<!-- 쿠폰사용 모달 -->
			<modal-use-coupon v-if="accessToken" ref="modal-use-coupon" v-model="coupons" :carts="carts" :deliveryPrice="deliveryPrice"></modal-use-coupon>

			<!-- 무통장 입금 모달 -->
			<modal-order-bank ref="modal-order-bank" v-model="order" @pay="submit" :totalPrice="totalPrice"></modal-order-bank>
		</main>

		<daum-postcode ref="daum-postcode"></daum-postcode>

		<!-- 푸터 -->
		<main-footer></main-footer>
	</div>
</template>

<script>
import MainHeader from "../../../components/client/inc/main-header.vue";
import MainFooter from "../../../components/client/inc/main-footer.vue";
import ModalUseCoupon from "../../../components/client/shop/modal-use-coupon.vue";
import ModalOrderBank from "../../../components/client/shop/modal-order-bank.vue";

import DaumPostcode from "../../../components/plugins/daum/daum-postcode.vue";

import detect from "../../../plugins/detect.js";

export default{
	components: {
		MainHeader,
		MainFooter,
		ModalUseCoupon,
		ModalOrderBank,
		DaumPostcode
	},
	data: function() {
		return{
			detect,

			ready: false,
			showModalUseCoupon: false,
			showModalOrderBank: false,

			me: null,

			pickup: { _carts: [] },
			carts: [],
			coupons: [],

			order: {
				_coupons: [],
				_carts: [],
				point: 0,
				sender: {
		            name: null,
		            phone: null,
					email: null,
		            postcode: null,
		            address1: null,
		            address2: null
		        },
		        receiver: {
					_id: null,
					title: null,
		            name: null,
		            phone: null,
					email: null,
		            postcode: null,
		            address1: null,
		            address2: null
		        },
				account: {
					accountNumber: null,
					accountHolder: null,
					accountBankCode: null
				},
				cashRecipt: {
					enabled: false,
					phone: null
				},
				requestMessage: null,
				paymentMethod: 'account',
			},

			defaultShippingDestination: {
				_id: null,
				title: null,
				name: null,
				phone: null,
				email: null,
				postcode: null,
				address1: null,
				address2: null
			},

			shippings: [],
			islands: [],

			senderPhone1: '010',
			senderPhone2: null,
			senderPhone3: null,
			senderEmail1: null,
			senderEmail2: null,

			receiverPhone1: '010',
			receiverPhone2: null,
			receiverPhone3: null,
			receiverEmail1: null,
			receiverEmail2: null
		}
	},
	mounted() {
		this.init();
	},
	methods: {
		openModalUseCoupon(){
			this.$refs['modal-use-coupon'].open();
		},

		openModalOrderBank(){
			this.$refs['modal-order-bank'].open();
		},

		async init() {
			try{
				if(!this.$store.state.pickup.length){
					alert("구매할 상품을 선택해주세요");
					this.$router.go(-1);
					return;
				}

				this.order._carts = this.$store.state.pickup;
				this.carts = await this.getCarts();

				await this.getShippings();
				await this.getIslands();
				if(this.accessToken){
					var { user } = await this.getMyInfo();
					var [ phone1, phone2, phone3 ] = user.phone ? user.phone.phoneNumberFormat().split('-') : [];
					var [ email1, email2 ] = user.email ? user.email.split('@') : [];
					Object.assign(this.order.sender, user);
					Object.assign(this.$data, {
						senderPhone1: phone1,
						senderPhone2: phone2,
						senderPhone3: phone3,
						senderEmail1: email1,
						senderEmail2: email2
					});

					this.me = user;

					if(!user.shippingDestinations) user.shippingDestinations = [];
					if(this.basicShippingDestination) this.updateReceiver(this.basicShippingDestination);
				}

				this.ready = true;
			}
			catch(error){
				console.error(error);
				if(error.response) alert(error.response.data.message);
			}
		},

		pay(){
			switch(this.order.paymentMethod){
				case "account": {
					this.submit();
					// this.openModalOrderBank();
					break;
				}
				case "card": {
					this.submit();
					break;
				}
				case "kakaopay": {
					this.submit();
					break;
				}
			}
		},

		async submit(){
			try{
				var order = await this.postOrder();

				switch(order.paymentMethod){
					case "account": {
						this.$router.push({
							path: "/shop/purchase",
							query: {
								merchant_uid: order.orderNo,
							}
						});
						break;
					}
					case "card": {
						IMP.init('imp87646109'); // 'iamport' 대신 부여받은 "가맹점 식별코드"를 사용
						IMP.request_pay({
							pg : 'inicis', // version 1.1.0부터 지원.
							pay_method : 'card',
							merchant_uid : order.orderNo,
							name : this.carts[0].product.name,
							amount : order.totalPrice,
							buyer_email : order.sender.email,
							buyer_name : order.sender.name,
							buyer_tel : order.sender.phone,
							buyer_addr : order.sender.address1,
							buyer_postcode : order.sender.postcode,
							company: "마켓쿵",
							m_redirect_url : `${window.location.protocol}//${window.location.hostname}/shop/purchase`
						}, (rsp) => {
							console.log({ rsp });
							this.$router.push({
								path: "/shop/purchase",
								query: {
									imp_uid: rsp.imp_uid,
									merchant_uid: rsp.merchant_uid
								}
							});
						});
						break;
					}
					case "kakaopay": {
						var result = await this.postKakaoPayReady(order._id);
						console.log(result);

						switch(this.detect){
							case "pc": {
								window.location.href = result.next_redirect_pc_url;
								break;
							}
							case "mobile": {
								window.location.href = result.next_redirect_mobile_url;
								break;
							}
						}

						break;
					}
				}

			}
			catch(error){
				console.error(error);
				if(error.response) alert(error.response.data.message);
			}
		},

		checkPoint(){
			if(this.me.point < this.order.point) this.order.point = this.me.point;
			if(this.totalPrice < this.order.point) this.order.point = this.totalPrice;
		},

		findAddress(user){
			this.$refs['daum-postcode'].open();
			this.$refs['daum-postcode'].$on('input', (data) => {
				user.postcode = data.postcode;
				user.address1 = data.address;
			});
		},

		updateReceiver(shippingDestination){
			Object.assign(this.order.receiver, shippingDestination);

			this.receiverPhone1 = shippingDestination.phone ? shippingDestination.phone.replace(/-/g, '').substr(0, 3) : '010';
			this.receiverPhone2 = shippingDestination.phone ? shippingDestination.phone.replace(/-/g, '').substr(3, 4) : null;
			this.receiverPhone3 = shippingDestination.phone ? shippingDestination.phone.replace(/-/g, '').substr(7, 4) : null;
			this.receiverEmail1 = shippingDestination.email ? shippingDestination.email.split('@')[0] : null;
			this.receiverEmail2 = shippingDestination.email ? shippingDestination.email.split('@')[1] : null;
		},

		async removeShippingDestination(shippingDestination){
			try{
				if(confirm("해당 배송지를 삭제하시겠습니까?")){
					this.me.shippingDestinations.splice(this.me.shippingDestinations.indexOf(shippingDestination), 1);
					await this.putMyInfo(this.me);
				}
			}
			catch(error){
				console.error(error);
				alert(error.response ? error.response.data.message : error.message);
			}
		},

		getCarts(){
			return new Promise(async (resolve, reject) => {
				try{
					var res = await this.$http.get('/api/v1/me/carts', {
						params: {
							_carts: this.$store.state.pickup
						}
					});

					resolve(res.data.carts);
				}
				catch(error){
					reject(error);
				}
			});
		},

		getCart(_cart){
			return new Promise(async (resolve, reject) => {
				try{
					var res = await this.$http.get(`/api/v1/me/carts/${_cart}`);

					resolve(res.data.cart);
				}
				catch(error){
					reject(error);
				}
			});
		},

		getShippings(){
			return new Promise(async (resolve, reject) => {
				try{
					var res = await this.$http.get('/api/v1/shop/shippings');

					this.shippings = res.data.shippings;

					resolve();
				}
				catch(error){
					reject(error);
				}
			});
		},

		getIslands(){
			return new Promise(async (resolve, reject) => {
				try{
					var res = await this.$http.get('/api/v1/shop/islands');

					this.islands = res.data.islands;

					resolve();
				}
				catch(error){
					reject(error);
				}
			});
		},

		getMyInfo(){
			return new Promise(async (resolve, reject) => {
				try{
					var res = await this.$http.get('/api/v1/me/info');

					resolve(res.data);
				}
				catch(error){
					reject(error);
				}
			});
		},

		putMyInfo(me){
			return new Promise(async (resolve, reject) => {
				try{
					var res = await this.$http.put('/api/v1/me/info', me);

					resolve(res.data);
				}
				catch(error){
					reject(error);
				}
			})
		},

		postOrder(){
			return new Promise(async (resolve, reject) => {
				try{
					var res = await this.$http.post('/api/v1/shop/orders', this.order);

					if(this.$store.state.baskets && this.$store.state.pickSample){
						let baskets = this.$store.state.baskets;
						for(let el of this.$store.state.pickSample){
							let index = baskets.findIndex(basket => basket._product === el.product._id)
							baskets.splice(index,1)
						}
						this.$store.dispatch("addToBasket", baskets);
						this.$store.dispatch("setPickSample", []);
					}

					resolve(res.data.order);
				}
				catch(error){
					reject(error);
				}
			});
		},

		postKakaoPayReady(_order){
			return new Promise(async (resolve, reject) => {
				try{
					var res = await this.$http.post('/api/kakao/payment/ready', { _order });

					resolve(res.data);
				}
				catch(error){
					reject(error);
				}
			});
		},

	},
	computed: {
		///////////////////////////////////////////////////////////////////////
		// 액세스 토큰
		///////////////////////////////////////////////////////////////////////
		accessToken(){
			return this.$store.state.accessToken;
		},
		payload(){
			return this.$store.state.payload;
		},
		///////////////////////////////////////////////////////////////////////
		// 구매목록을 상품별로 묶음
		///////////////////////////////////////////////////////////////////////
		cartsMap(){
			var cartsMap = new Map();
			this.carts.forEach((cart) => {
				var value = cartsMap.get(cart._product);
				if(value) value.carts.push(cart);
				cartsMap.set(cart._product, value ? value : { product: cart.product, carts: [cart] });
			});
			console.log(Array.from(cartsMap.values()));
			return Array.from(cartsMap.values());
		},
		///////////////////////////////////////////////////////////////////////
		// 상품가 합계
		///////////////////////////////////////////////////////////////////////
		productPrice(){
			return this.carts.reduce((productPrice, cart) => {
				return productPrice + ((cart.salePrice + cart.discountPrice) * cart.amount);
			}, 0);
		},
		///////////////////////////////////////////////////////////////////////
		// 할인가 합계
		///////////////////////////////////////////////////////////////////////
		discountPrice(){
			return this.carts.reduce((discountPrice, cart) => {
				return discountPrice + (cart.discountPrice * cart.amount);
			}, 0);
		},
		///////////////////////////////////////////////////////////////////////
		// 쿠폰할인금액 합계
		///////////////////////////////////////////////////////////////////////
		couponPrice(){
			var couponPrice = 0;
			for(var i in this.coupons){
				var coupon = this.coupons[i];
				switch(coupon.type){
					case "product": {
						var sum = this.carts.reduce((sum, cart) => {
							if(cart.product._id == coupon._product){
								return sum + (cart.salePrice * cart.amount);
							}
							return sum;
						}, 0);

						if(coupon.unit == '원') couponPrice += coupon.amount;
						else if(coupon.unit == '%'){
							if(!coupon.maxPrice) couponPrice += Math.ceil(sum * coupon.amount / 100);
							else if(coupon.maxPrice <= sum) couponPrice += coupon.maxPrice;
						}
						break;
					}
					case "category": {
						var sum = this.carts.reduce((sum, cart) => {
							if(cart.product._category == coupon._category){
								return sum + (cart.salePrice * cart.amount);
							}
							return sum;
						}, 0);

						if(coupon.unit == '원') couponPrice += coupon.amount;
						else if(coupon.unit == '%'){
							if(!coupon.maxPrice) couponPrice += Math.ceil(sum * coupon.amount / 100);
							else if(coupon.maxPrice <= sum) couponPrice += coupon.maxPrice;
						}
						break;
					}
					case "order": {
						var sum = this.carts.reduce((sum, cart) => {
							return sum + (cart.salePrice * cart.amount);
						}, 0);

						if(coupon.unit == '원') couponPrice += coupon.amount;
						else if(coupon.unit == '%'){
							if(!coupon.maxPrice) couponPrice += Math.ceil(sum * coupon.amount / 100);
							else if(coupon.maxPrice <= sum) couponPrice += coupon.maxPrice;
						}
						break;
					}
					case "delivery": {
						couponPrice += coupon.amount;
						break;
					}
				}
			}
			return couponPrice;
		},
		///////////////////////////////////////////////////////////////////////
		// 배송비 합계
		///////////////////////////////////////////////////////////////////////
		deliveryPrice(){
			var { deliveryPrice, orderPrice } = this.cartsMap.reduce(({ deliveryPrice, orderPrice }, map) => {
				switch(map.product.shipping?.code){
					case "product": { deliveryPrice += map.product.shipping.price; break; }
					case "order": { orderPrice += map.carts.reduce(( productPrice, cart ) => { return productPrice + cart.salePrice * cart.amount; }, 0); break; }
				}
				return { deliveryPrice, orderPrice };
			}, { deliveryPrice: 0, orderPrice: 0 });

			if(orderPrice){
				var shipping = this.shippings.find((shipping) => shipping.code == "order");
				deliveryPrice += (shipping.range.basePrice <= orderPrice ) ? shipping.range.highPrice : shipping.range.lowPrice;
			}

			return deliveryPrice;
		},
		///////////////////////////////////////////////////////////////////////
		// 도서산간비용 합계
		///////////////////////////////////////////////////////////////////////
		islandPrice(){
			return this.cartsMap.reduce((islandPrice, map) => {
				if(map.product.islandsEnabled && this.order.receiver.postcode){
					var island = this.islands.find((island) => island.areas.find((area) => area.postcode == this.order.receiver.postcode));
					if(island){
						console.log(island);
						return islandPrice + map.product.islands.find((item) => item.code == island.code).price;
					}
				}
				return islandPrice;
			}, 0);
		},

		totalPrice(){
			if(this.ready){
				return this.productPrice + this.deliveryPrice - this.discountPrice - this.couponPrice - this.order.point;
			}
			return 0;
		},

		///////////////////////////////////////////////////////////////////////
		// 기본 배송지
		///////////////////////////////////////////////////////////////////////
		basicShippingDestination(){
			if(this.me) return this.me.shippingDestinations.find((shippingDestination) => shippingDestination.basic);
		}

	},
	watch: {
		senderPhone1(){ this.order.sender.phone = [this.senderPhone1, this.senderPhone2, this.senderPhone3].join('-')},
		senderPhone2(){ this.order.sender.phone = [this.senderPhone1, this.senderPhone2, this.senderPhone3].join('-')},
		senderPhone3(){ this.order.sender.phone = [this.senderPhone1, this.senderPhone2, this.senderPhone3].join('-')},
		senderEmail1(){ this.order.sender.email = [this.senderEmail1, this.senderEmail2].join('@')},
		senderEmail2(){ this.order.sender.email = [this.senderEmail1, this.senderEmail2].join('@')},

		receiverPhone1(){ this.order.receiver.phone = (this.receiverPhone1 && this.receiverPhone2 && this.receiverPhone3) ? [this.receiverPhone1, this.receiverPhone2, this.receiverPhone3].join('-') : "" },
		receiverPhone2(){ this.order.receiver.phone = (this.receiverPhone1 && this.receiverPhone2 && this.receiverPhone3) ? [this.receiverPhone1, this.receiverPhone2, this.receiverPhone3].join('-') : "" },
		receiverPhone3(){ this.order.receiver.phone = (this.receiverPhone1 && this.receiverPhone2 && this.receiverPhone3) ? [this.receiverPhone1, this.receiverPhone2, this.receiverPhone3].join('-') : "" },
		receiverEmail1(){ this.order.receiver.email = (this.receiverEmail1 && this.receiverEmail2) ? [this.receiverEmail1, this.receiverEmail2].join('@') : ""},
		receiverEmail2(){ this.order.receiver.email = (this.receiverEmail1 && this.receiverEmail2) ? [this.receiverEmail1, this.receiverEmail2].join('@') : ""},

		coupons(){
			this.order._coupons = this.coupons.map((coupon) => { return coupon._id });
		}
	}
}
</script>

<template>
	<div class="wrap wrap--products">
		<!-- 헤더 -->
		<main-header></main-header>

		<!-- 메인 -->
		<main class="main" role="main">
			<div class="container">
				<div class="product-list-container">
					<!-- 상품 카테고리 -->
					<nav class="product-categories">
						<ul class="product-category">
							<li class="product-category__list">
								<a class="product-category__list-link" href=""><span>카테고리 전체</span></a>
							</li>
							<li class="product-category__list">
								<a class="product-category__list-link" data-toggle="opened"><i class="arrow"></i><span>강아지</span></a>
								<ul class="product-category__level product-category__level--1">
									<li>
										<a class="product-category__level-link" data-toggle="opened"><span>사료</span></a>
										<ul class="product-category__level product-category__level--2">
											<li><a class="on" href="#"><span>수제간식</span></a></li>
											<li><a href="#"><span>덴탈껌</span></a></li>
											<li><a href="#"><span>져키/트릿</span></a></li>
											<li><a href="#"><span>동결건조</span></a></li>
											<li><a href="#"><span>파우더</span></a></li>
											<li><a href="#"><span>캔/파우치</span></a></li>
											<li><a href="#"><span>영양제</span></a></li>
											<li><a href="#"><span>쿠키</span></a></li>
											<li><a href="#"><span>소시지</span></a></li>
											<li><a href="#"><span>펫드링크</span></a></li>
										</ul>
									</li>
									<li>
										<a class="product-category__level-link" data-toggle="closed"><span>간식</span></a>
									</li>
									<li>
										<a class="product-category__level-link" data-toggle="closed"><span>용품</span></a>
									</li>
								</ul>
							</li>
							<li class="product-category__list">
								<a class="product-category__list-link" data-toggle="closed"><i class="arrow"></i><span>고양이</span></a>
							</li>
						</ul>
					</nav>

					<!-- 상품 리스트 -->
					<section class="product-lists">
						<div class="product-lists__head">
							<div class="product-lists__total">
								<span><em>1,000</em> 개의 상품이 있습니다.</span>
							</div>
							<div class="product-lists__order">
								<div class="product-lists__group">
									<ul class="product-lists__group-lists">
										<li><a class="on" href="#">베스트</a></li>
										<li><a href="#">신상품</a></li>
										<li><a href="#">높은 가격순</a></li>
										<li><a href="#">낮은 가격순</a></li>
									</ul>
								</div>
								<div class="product-lists__select">
									<select class="product-lists__select-option" name="" id="">
										<option value="">20개 보기</option>
									</select>
								</div>
							</div>
						</div>
						<div class="product-lists__body">
							<div class="grid">
								<div class="grid-8">
									<a class="product-list" href="/shop/products/view">
										<span class="product-list__thumb"><img class="product-list__thumbnail" src="/images/sample/product-thumbnail-medium.jpg" alt=""></span>
										<span class="product-list__title">[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개</span>
										<span class="product-list__price">
											<span class="product-list__price-left">
												<em class="product-list__price-num">15,000</em>원
												<span class="product-list__price-percent">25%</span>
											</span>
										</span>
									</a>
								</div><div class="grid-8">
									<a class="product-list" href="/shop/products/view">
										<span class="product-list__thumb"><img class="product-list__thumbnail" src="/images/sample/product-thumbnail-medium.jpg" alt=""></span>
										<span class="product-list__title">[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개</span>
										<span class="product-list__price">
											<span class="product-list__price-left">
												<em class="product-list__price-num">15,000</em>원
												<span class="product-list__price-percent">25%</span>
											</span>
										</span>
									</a>
								</div><div class="grid-8">
									<a class="product-list" href="/shop/products/view">
										<span class="product-list__thumb"><img class="product-list__thumbnail" src="/images/sample/product-thumbnail-medium.jpg" alt=""></span>
										<span class="product-list__title">[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개</span>
										<span class="product-list__price">
											<span class="product-list__price-left">
												<em class="product-list__price-num">15,000</em>원
												<span class="product-list__price-percent">25%</span>
											</span>
										</span>
									</a>
								</div><div class="grid-8">
									<a class="product-list" href="/shop/products/view">
										<span class="product-list__thumb"><img class="product-list__thumbnail" src="/images/sample/product-thumbnail-medium.jpg" alt=""></span>
										<span class="product-list__title">[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개</span>
										<span class="product-list__price">
											<span class="product-list__price-left">
												<em class="product-list__price-num">15,000</em>원
												<span class="product-list__price-percent">25%</span>
											</span>
										</span>
									</a>
								</div><div class="grid-8">
									<a class="product-list" href="/shop/products/view">
										<span class="product-list__thumb"><img class="product-list__thumbnail" src="/images/sample/product-thumbnail-medium.jpg" alt=""></span>
										<span class="product-list__title">[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개</span>
										<span class="product-list__price">
											<span class="product-list__price-left">
												<em class="product-list__price-num">15,000</em>원
												<span class="product-list__price-percent">25%</span>
											</span>
										</span>
									</a>
								</div>
							</div>

							<div class="product-lists__pagination">
								<div class="pagination">
									<a class="pagination__backward" href=""></a>
									<a class="pagination__prev" href=""></a>
									<a class="current">1</a>
									<a class="" href="">2</a>
									<a class="pagination__next" href=""></a>
									<a class="pagination__forward" href=""></a>
								</div>
							</div>
						</div>
					</section>
				</div>
			</div>
		</main>

		<!-- 푸터 -->
		<main-footer></main-footer>
	</div>
</template>

<script>
import MainHeader from "../../../components/client/inc/main-header.vue";
import MainFooter from "../../../components/client/inc/main-footer.vue";

export default{
	components: {
		MainHeader,
		MainFooter
	},
	created() {
	},
	mounted() {
		this.init();
	},
	methods : {
		init : function() {
		}
	},
}
</script>

<template>
	<div class="wrap wrap--sub">
		<!-- 헤더 -->
		<main-header></main-header>

		<!-- 메인 -->
		<main class="main" role="main">
            <div class="container">
                <div class="collection-index-container">
                    <div class="page-head">
                        <div class="page-head__inner">
                            <h1 class="page-head__title">SHOPS</h1>
                            <ul class="breadcrumb">
                                <li class="breadcrumb__list">디아이디는 전국 1,500여개의 취급점에서 만나실 수 있습니다.</li>
                                <!-- <li class="breadcrumb__list">컬렉션</li>
                                <li class="breadcrumb__list">국내</li>
                                <li class="breadcrumb__list">디앤디 컬렉션 인덱스</li> -->
                            </ul>
                        </div>
                    </div>
                    <div class="shops-container">
                        <div class="board-container">
                            <div class="search-container">
                                <div class="search-header">
                                    <h3 class="search-title">디아이디 취급업체 찾기</h3>
                                </div>
                                <div class="search-body">
                                    <div class="form-input-btn">
                                        <select v-model="filter.sido" name="" id="" class="select" @change="filter.gugun = undefined;">
                                            <option :value="undefined">시/도</option>
											<option v-for="sido in sidoGugun" :value="sido.name">{{ sido.name }}</option>
                                        </select>
                                        <select v-model="filter.gugun" name="" id="" class="select">
                                            <option :value="undefined">구/군</option>
											<option v-for="gugun in filter.sido ? sidoGugun.find((sido) => sido.name == filter.sido).gugun : []" :value="gugun.name">{{ gugun.name }}</option>
                                        </select>
                                        <input v-model="filter.searchValue" type="text" class="input" placeholder="매장명 검색하기">
                                        <button @click="search" class="button button--square button--bluegrey">검색</button>
                                    </div>
                                </div>
                            </div>

                            <div role="table" class="board-table board-table--search">
                                <div role="rowgroup">
                                    <div role="row">
                                        <div class="store-area" role="columnheader">지역</div>
                                        <div class="store-name" role="columnheader">취급점명</div>
                                        <div class="store-address" role="columnheader">주소</div>
                                        <div class="store-phone" role="columnheader">연락처</div>
                                        <div class="store-collections" role="columnheader">취급컬렉션</div>
                                        <div class="store-btn" role="columnheader">상세정보</div>
                                    </div>
                                </div>
                                <div role="rowgroup">
                                    <div v-for="(board, index) in boards" :key="index" role="row">
                                        <div class="store-area" role="cell">{{ board.area }}</div>
                                        <div class="store-name" role="cell">{{ board.storeName }}</div>
                                        <div class="store-address" role="cell">{{ board.address1 }} {{ board.address2 }}</div>
                                        <div class="store-phone" role="cell">{{ board.storePhone }}</div>
                                        <div class="store-collections" role="cell">
                                            <span v-for="(collection, index) in board.collections" :key="index" class="label label-gray">{{ collection }}</span>
                                        </div>
                                        <div class="store-btn" role="cell">
                                            <a role="link" :href="`/shops/view/${board._id}`" class="button button--round button--point button--search"><i class="icon icon-search"></i>상세정보</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <pagination-component v-model="page" :count="pageCount" @input="search()"></pagination-component>
                            <!-- <div class="board-buttons">
                                <a class="button button--border" href=""><span>리스트</span></a>
                                <a class="button button--primary" href=""><span>글쓰기</span></a>
                            </div> -->
                        </div>
                        <!-- <img onclick="window.location='/shops/view'" style="cursor:pointer;" src="/images/sub/shops/shop.png" alt=""> -->
                    </div>
                </div>
            </div>
		</main>

		<!-- 푸터 -->
		<main-footer></main-footer>
	</div>
</template>

<script>
import MainHeader from '@/components/client/inc/main-header.vue';
import MainFooter from '@/components/client/inc/main-footer.vue';
import PaginationComponent from '@/components/client/pagination-component.vue';

import sidoGugun from '@/plugins/sido-gugun.json';

export default{
	components: {
		MainHeader,
		MainFooter,
		PaginationComponent
	},
	data() {
		return {
			sidoGugun,

			filter: {
                code: "interior-shop",
				sido: this.$route.query.sido,
				gugun: this.$route.query.gugun,
				searchKey: "storeName",
				searchValue: this.$route.query.searchValue
			},

			boards: [],

			page: +this.$route.query.page || 1,
			pageCount: 0,
			limit: 15,
		};
	},
	mounted() {
		this.init();
	},
	methods: {
		async init(){
			try{
                this.search(false);
			}
			catch(error){
				console.error(error);
				alert(error.response ? error.response.data.message : error.message);
			}
		},
		async search(routable = true){
			try{
                if(routable){
                    this.$router.push({
                        query: Object.assign({}, this.filter, { page: this.page })
                    });
                }

				var { summary, boards } = await this.getBoards({
                    headers: {
                        skip: (this.page - 1) * this.limit,
                        limit: this.limit
                    },
                    params: this.filter
                });

                this.boards = boards;
                this.pageCount = Math.ceil(summary.totalCount / this.limit);
			}
			catch(error){
				console.error(error);
				alert(error.response ? error.response.data.message : error.message);
			}
		},
        getBoards(data){
            return this.$http.get('/api/v1/boards', data).then(result => result.data);
        }
	},
}
</script>

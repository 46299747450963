<template>
	<div class="wrap wrap--sub">
		<!-- 헤더 -->
		<main-header></main-header>

		<!-- 메인 -->
		<main class="main" role="main">
            <div class="container">
                <div class="collection-index-container">
                    <div class="page-head">
                        <div class="page-head__inner">
                            <h1 class="page-head__title">{{ board.storeName }}</h1>
                            <ul class="breadcrumb">
                                <li class="breadcrumb__list">{{ board.summary }}</li>
                                <!-- <li class="breadcrumb__list">컬렉션</li>
                                <li class="breadcrumb__list">국내</li>
                                <li class="breadcrumb__list">디앤디 컬렉션 인덱스</li> -->
                            </ul>
                        </div>
                    </div>

                    <div class="shops-view-container">
                        <div class="shops-view-section">
                            <h2 class="page-title26 page-title--line">업체정보</h2>
                            <div class="shops-info">
                                <div class="shops-info-left">
                                    <div class="info-list">
                                        <ul>
                                            <li><em>지역</em>{{ board.area }}</li>
                                            <li><em>취급점명</em>{{ board.storeName }}</li>
                                            <li><em>주소</em>{{ board.address1 }} {{ board.address2 }}</li>
                                            <li><em>연락처</em>{{ board.storePhone }}</li>
                                            <li><em>이메일</em>{{ board.storeEmail }}</li>
                                            <li><em>취급컬렉션</em><span v-for="collection in board.collections" class="label label-gray">{{ collection }}</span></li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="shops-info-right">
                                    <div class="info-map">
										<div id="map" style="width:100%;height:400px;"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

						<div v-if="(board.relativeProducts || []).filter(product => product._id).length" role="row" class="shops-view-section">
							<h2 class="page-title26 page-title--line">관련제품</h2>
							<ul class="board-product-lists">
								<li v-for="product in board.relativeProducts.filter(product => product._id)" class="board-product-list">
									<a class="board-product" :href="`/collection/${product.paperCollection.code}?code=${product.code}`">
										<div class="board-product__thumb">
											<span class="board-product__link">
												<img class="board-product__thumbnail" :src="product.thumb" alt="">
											</span>
										</div>
										<div class="board-product__info">
											<span class="board-product__text">{{ product.paperCollection.name }}</span>
											<span class="board-product__title">{{ product.name }} {{ product.nameEn }}</span>
											<span class="board-product__text">[{{ product.code }}]</span>
										</div>
									</a>
								</li>
							</ul>
						</div>

                        <div class="shops-view-section">
                            <h2 class="page-title26 page-title--line">시공 포트폴리오</h2>
                            <div class="center-board">
                                <!-- 1. 게시판 리스트: 포토 베이직3 스킨 -->
                                <board-photo-basic3-list :boards="board.portfolios"></board-photo-basic3-list>
                            </div>
                        </div>
                        <!-- <img onclick="window.location='/shops/view'" style="cursor:pointer;" src="/images/sub/shops/shop_view2.png" alt=""> -->
                    </div>
                </div>
            </div>
		</main>

		<!-- 푸터 -->
		<main-footer></main-footer>
	</div>
</template>

<script>
import MainHeader from '@/components/client/inc/main-header.vue';
import MainFooter from '@/components/client/inc/main-footer.vue';
import BoardPhotoBasic3List from "@/components/client/board/skin/board-photo-basic3/list.vue";

export default{
	components: {
		MainHeader,
		MainFooter,
        BoardPhotoBasic3List
	},
	data: () => {
		return {
			board: {}
		};
	},
	mounted() {
		this.init();
	},
	methods: {
		async init(){
			try{
				this.board = await this.getBoard();
				this.$nextTick(() => {
					var storeAddress = this.board.address1;
					var storeName = this.board.storeName;
					var mapContainer = document.getElementById('map'), // 지도를 표시할 div
					mapOption = {
						center: new kakao.maps.LatLng(33.450701, 126.570667), // 지도의 중심좌표
						level: 3 // 지도의 확대 레벨
					};

					// 지도를 생성합니다
					var map = new kakao.maps.Map(mapContainer, mapOption);

					// 주소-좌표 변환 객체를 생성합니다
					var geocoder = new kakao.maps.services.Geocoder();

					// 주소로 좌표를 검색합니다
					geocoder.addressSearch(storeAddress, function(result, status) {

						// 정상적으로 검색이 완료됐으면
						if (status === kakao.maps.services.Status.OK) {

							var coords = new kakao.maps.LatLng(result[0].y, result[0].x);

							// 결과값으로 받은 위치를 마커로 표시합니다
							var marker = new kakao.maps.Marker({
								map: map,
								position: coords
							});

							// 인포윈도우로 장소에 대한 설명을 표시합니다
							var infowindow = new kakao.maps.InfoWindow({
								content: `<div style="width:150px;text-align:center;padding:6px 0;">${storeName}</div>`
							});
							infowindow.open(map, marker);

							// 지도의 중심을 결과값으로 받은 위치로 이동시킵니다
							map.setCenter(coords);
						}
					});
				});
			}
			catch(error){
				console.error(error);
				if(error.response) alert(error.response.data.message);
				else alert(error.message);
			}
		},

		getBoard(){
			return new Promise(async (resolve, reject) => {
				try{
					var res = await this.$http.get(`/api/v1/boards/${this.$route.params._board}`);

					resolve(res.data.board);
				}
				catch(error){
					reject(error);
				}
			});
		}
	}
}
</script>

<template>
	<div class="wrap wrap--products">
		<!-- 헤더 -->
		<main-header></main-header>

		<!-- 메인 -->
		<main class="main" role="main">
			<div class="container">
				<section class="product-view-container">
					<!-- 상품 상세: 요약 -->
                    <section class="product-intro">
						<!-- 상품 이미지 -->
                        <div class="product-intro__thumb">
							<!-- 기본 이미지 -->
                            <div class="product-intro__thumb-main">
                                <img src="/images/sample/product-thumbnail-large.jpg" alt="">
                            </div>
							<!-- 추가 이미지 -->
                            <ul class="product-intro__thumb-lists">
                                <li class="product-intro__thumb-list"><div class="product-intro__thumb-button" role="button" aria-pressed="true"><img src="/images/sample/product-thumbnail-large.jpg" alt=""></div></li>
                                <li class="product-intro__thumb-list"><div class="product-intro__thumb-button" role="button" aria-pressed="false"><img src="/images/sample/product-thumbnail-large.jpg" alt=""></div></li>
                                <li class="product-intro__thumb-list"><div class="product-intro__thumb-button" role="button" aria-pressed="false"><img src="/images/sample/product-thumbnail-large.jpg" alt=""></div></li>
                                <li class="product-intro__thumb-list"><div class="product-intro__thumb-button" role="button" aria-pressed="false"><img src="/images/sample/product-thumbnail-large.jpg" alt=""></div></li>
                                <li class="product-intro__thumb-list"><div class="product-intro__thumb-button" role="button" aria-pressed="false"><img src="/images/sample/product-thumbnail-large.jpg" alt=""></div></li>
                            </ul>
                        </div>

						<!-- 상품 가격 -->
                        <div class="product-intro__info">
							<!-- 상품명 -->
                            <div class="product-intro__name">
                                <h1 class="product-intro__name-text">[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개</h1>
                            </div>
							<!-- 상품 요약 테이블 -->
                            <div class="product-intro__table">
                                <!-- 상품 가격 그룹 -->
                                <div class="product-intro__table-row-group">
                                    <div class="product-intro__table-row">
                                        <div class="product-intro__table-th">판매가</div>
                                        <div class="product-intro__table-td">
                                            <span class="product-intro__price">
                                                <span class="percent">25%</span>
                                                <span class="current"><em class="num">15,000</em>원</span>
                                                <span class="origin">18,000원</span>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="product-intro__table-row">
                                        <div class="product-intro__table-th">배송비</div>
                                        <div class="product-intro__table-td">무료배송</div>
                                    </div>
                                </div>
                                <!-- 상품 옵션 그룹 -->
                                <div class="product-intro__table-row-group">
                                    <div class="product-intro__table-row">
                                        <div class="product-intro__table-th">옵션선택</div>
                                        <div class="product-intro__table-td">
                                            <select class="product-intro__select" name="">
                                                <option value="" disabled selected>옵션을 선택하세요.</option>
                                                <option value="">닭고기</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
							<!-- 선택한 옵션 박스 그룹 -->
							<div class="product-intro__option-box-group">
								<!-- 옵션 박스 -->
								<div class="product-option-box">
									<button class="product-option-box__delete" type="button"><span class="blind">삭제</span></button>
									<span class="product-option-box__title">옵션: 닭고기</span>
									<div class="product-option-box__flex">
										<!-- 옵션 수량 -->
										<div class="spinner">
											<button class="spinner__minus" type="button"></button>
											<input class="spinner__num" type="number" name="" value="1">
											<button class="spinner__plus" type="button"></button>
										</div>
										<div class="product-option-box__price">
											<span class="price"><em class="num">15,000</em>원</span>
										</div>
									</div>
								</div>
							</div>
							<!-- 총 상품 금액 -->
							<div class="product-intro__total">
								<span class="price">총<em class="num">15,000</em>원</span>
							</div>
							<!-- 버튼 영역 -->
							<div class="product-intro__buttons">
								<a href="/mypage/cart" class="button button--border-primary"><span>장바구니</span></a>
								<a href="/shop/order" class="button button--primary"><span>바로구매</span></a>
							</div>
                        </div>

						<!-- 할인쿠폰 -->
                        <div class="product-intro__coupon">
							<span class="product-intro__coupon-title"><b>이 상품!</b> <br>할인가능쿠폰</span>
							<ul class="product-intro__coupon-lists">
								<li><a class="button" href="#" onclick="return:false;"><img src="/images/sample/coupon1.jpg" alt=""></a></li>
								<li><a class="button" href="#" onclick="return:false;"><img src="/images/sample/coupon2.jpg" alt=""></a></li>
								<li><a class="button" href="#" onclick="return:false;"><img src="/images/sample/coupon3.jpg" alt=""></a></li>
								<li><a class="button" href="#" onclick="return:false;"><img src="/images/sample/coupon4.jpg" alt=""></a></li>
							</ul>
                        </div>
                    </section>

					<!-- 상품 상세: 콘텐츠 -->
                    <section class="product-details">
						<!-- 탭 메뉴 -->
						<nav class="product-details__navigation">
							<div class="product-details__navigation-inner">
								<ul class="product-details__nav">
									<li><button class="product-details__nav-tab" type="button" data-tab-button="main" aria-pressed="true"><span>상품정보</span></button></li>
									<li><button class="product-details__nav-tab" type="button" data-tab-button="review" aria-pressed="false"><span>구매후기</span></button></li>
									<li><button class="product-details__nav-tab" type="button" data-tab-button="qna" aria-pressed="false"><span>상품문의</span></button></li>
									<li><button class="product-details__nav-tab" type="button" data-tab-button="delivery" aria-pressed="false"><span>반품/교환</span></button></li>
								</ul>
							</div>
						</nav>
						<!-- 옵션 요약 사이드바 -->
						<div class="product-details__sidebar">
							<div class="product-sidebar" data-sidebar-fixed="off">
								<div class="product-sidebar__contents">
									<div class="product-sidebar__top">
										<span class="product-sidebar__title">[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개</span>
										<!-- 상품 옵션 그룹 -->
										<div class="product-sidebar__option-row-group">
											<div class="product-sidebar__option-row">
												<div class="product-sidebar__option-th">옵션선택</div>
												<div class="product-sidebar__option-td">
													<select class="product-intro__select" name="">
		                                                <option value="" disabled select>옵션을 선택하세요.</option>
		                                                <option value="">닭고기</option>
		                                            </select>
												</div>
											</div>
										</div>
										<!-- 선택한 옵션 박스 그룹 -->
										<div class="product-sidebar__option-box-group">
											<!-- 옵션 박스 -->
											<div class="product-option-box">
												<button class="product-option-box__delete" type="button"><span class="blind">삭제</span></button>
												<span class="product-option-box__title">옵션: 닭고기</span>
												<div class="product-option-box__flex">
													<!-- 옵션 수량 -->
													<div class="spinner">
														<button class="spinner__minus" type="button"></button>
														<input class="spinner__num" type="number" name="" value="1">
														<button class="spinner__plus" type="button"></button>
													</div>
													<div class="product-option-box__price">
														<span class="price"><em class="num">15,000</em>원</span>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="product-sidebar__bottom">
										<div class="product-sidebar__total">
											<span class="price">총<em class="num">15,000</em>원</span>
										</div>
										<div class="product-sidebar__buttons">
											<button class="button button--border-primary"><span>장바구니</span></button>
											<button class="button button--primary"><span>바로구매</span></button>
										</div>
										<!-- 페이 영역 -->
										<div class="product-sidebar__methods">

										</div>
									</div>
								</div>
							</div>
						</div>
						<!-- 상품 상세 콘텐츠 -->
						<div class="product-details__contents">
							<!-- 상품 상세: 메인 -->
							<div class="product-details__content">
								<div class="product-detail-container" data-tab-contents="main">
									<!-- 대표 내용 -->
									<section class="product-main">
										<div class="product-main__image"><img src="/images/sample/product-main-image.jpg" alt=""></div>
										<div class="product-main__title">
											<span class="product-main__title-sub">돼지함량 97%의 동결건조 주식</span>
											<h2 class="product-main__title-main">DOG[빅스비] 러블 돼지 2종</h2>
										</div>
										<div class="product-main__main-pr">
											<p>동결건조 사료는 영양소를 야생에 먹던 자연식에 무척 가깝게 구현해냈다고 하죠동결건조 사료는 영양소를 야생에 먹던 자연식에 무척 가깝게 구현해냈다고 하죠동결건조 사료는 영양소를 야생에 먹던 자연식에 무척 가깝게 구현해냈다고 하죠 동결건조 사료는 영양소를 야생에 먹던 자연식에 무척 가깝게 구현해냈다고 하죠동결건조 사료는 영양소를 야생에 먹던 자연식에 무척 가깝게 구현해냈다고 하죠</p>
										</div>
									</section>
									<!-- 상품 속성 -->
									<section class="product-composition">
										<section class="product-compose">
											<h3 class="product-compose__title-main">Check Point</h3>
											<h4 class="product-compose__title-sub">브랜드 & 생산업체 정보가 노출됩니다.</h4>
											<div class="grid">
												<div class="grid-12">
													<h5 class="title">재료와 성분</h5>
													<ul>
														<li>돼지 함량 97%</li>
														<li>돼지의 원육, 뼈, 내장을 모두함유 </li>
														<li>식이섬유가 풍부한 호박, 오메가 3&6을 함유한 연어오일</li>
														<li>그레인 프리</li>
													</ul>
												</div><div class="grid-12">
													<h5 class="title">생산 유통 과정</h5>
													<ul>
														<li>동결건조 방식으로제조해 영양소와 풍미 보존</li>
														<li>보관과 급여가 간편한 지퍼백 패키지 적용</li>
														<li>기호성 테스트용 128g과 신선하게 먹기 좋은 340g 용량</li>
													</ul>
												</div>
											</div>
										</section>
										<section class="product-compose">
											<h3 class="product-compose__title-main">Kong’s Pick</h3>
											<h4 class="product-compose__title-sub">01~02. 러블 돼지</h4>
											<div class="grid">
												<div class="grid-24">
													<ul>
														<li><b>선택 가이드 :</b> ①1팩(128g). ②1팩(340g) 중 선택하세요.</li>
														<li><b>주요 원재료 :</b> 닭고기(뼈,심장,간포함), 호박, 코코넛오일, 연어오일 등</li>
														<li><b>특징 :</b> 항생제와 호르몬제 없이 방목하여 키운 닭 함량 98%의 동결건조 주식으로, 뼈와 내장을 모두 포함해 칼슘과 인, 미네랄이 풍부해요. 물에 풀면 고소한 향이 살아납니다.</li>
													</ul>
												</div>
											</div>
											<div class="table">
												<dl class="tr">
													<dt class="th">반려견무게</dt>
													<dd class="td">3kg 미만</dd>
													<dd class="td">3kg이상 ~ 5kg 미만</dd>
													<dd class="td">5kg이상 ~ 10kg 미만</dd>
													<dd class="td">10kg이상 ~ 15kg 미만</dd>
												</dl>
												<dl class="tr">
													<dt class="th">권장 급여량</dt>
													<dd class="td">15g~20g</dd>
													<dd class="td">15g~20g</dd>
													<dd class="td">15g~20g</dd>
													<dd class="td">15g~20g</dd>
												</dl>
											</div>
										</section>
									</section>
									<!-- 상품 정보 -->
									<section class="product-sub">
										<img src="/images/sample/product-detail-image.jpg" alt="">
									</section>
								</div>
							</div>
							<!-- 상품 상세: 구매후기 -->
							<div class="product-details__content">
								<div class="product-review-container" data-tab-contents="review">
									<div class="product-review__head">
										<div class="product-review__order">
											<span class="product-review__total">구매후기 (10)</span>
											<span class="product-review__group">
												<ul class="product-review__group-lists">
													<li><a class="on">인기순</a></li>
													<li><a>최신순</a></li>
												</ul>
											</span>
										</div>
										<div class="product-review__buttons">
											<div class="button button--border-primary">구매후기 등록</div>
										</div>
									</div>
									<div class="product-review__body">
										<div class="review-box-container">
											<div class="review-box">
												<div class="review-box__head">
													<div class="review-box__evaluation">
														<!-- 별점 평가 -->
														<div class="star-rating-container">
															<span class="star-rating">
																<svg class="star" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 488.2">
																	<linearGradient id="starOrder1" x1="0" x2="100%" y1="0" y2="0">
																		<stop offset="100%" stop-color="#ffdc64"></stop>
																		<stop offset="100%" stop-color="#dddddd"></stop>
																	</linearGradient>
																	<path d="M499.9,176.4L334.1,161L268.2,8c-4.6-10.7-19.8-10.7-24.4,0l-65.9,153L12.1,176.4c-11.6,1.1-16.3,15.5-7.5,23.2l125.1,109.9L93,472c-2.6,11.4,9.7,20.3,19.7,14.3l143.2-85l143.2,85c10,6,22.3-3,19.8-14.3l-36.6-162.5l125.1-109.9C516.2,191.9,511.5,177.5,499.9,176.4" fill="url('#starOrder1')"/>
																</svg>
															</span>
															<span class="star-rating">
																<svg class="star" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 488.2">
																	<linearGradient id="starOrder2" x1="0" x2="100%" y1="0" y2="0">
																		<stop offset="100%" stop-color="#ffdc64"></stop>
																		<stop offset="100%" stop-color="#dddddd"></stop>
																	</linearGradient>
																	<path d="M499.9,176.4L334.1,161L268.2,8c-4.6-10.7-19.8-10.7-24.4,0l-65.9,153L12.1,176.4c-11.6,1.1-16.3,15.5-7.5,23.2l125.1,109.9L93,472c-2.6,11.4,9.7,20.3,19.7,14.3l143.2-85l143.2,85c10,6,22.3-3,19.8-14.3l-36.6-162.5l125.1-109.9C516.2,191.9,511.5,177.5,499.9,176.4" fill="url('#starOrder2')"/>
																</svg>
															</span>
															<span class="star-rating">
																<svg class="star" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 488.2">
																	<linearGradient id="starOrder3" x1="0" x2="100%" y1="0" y2="0">
																		<stop offset="100%" stop-color="#ffdc64"></stop>
																		<stop offset="100%" stop-color="#dddddd"></stop>
																	</linearGradient>
																	<path d="M499.9,176.4L334.1,161L268.2,8c-4.6-10.7-19.8-10.7-24.4,0l-65.9,153L12.1,176.4c-11.6,1.1-16.3,15.5-7.5,23.2l125.1,109.9L93,472c-2.6,11.4,9.7,20.3,19.7,14.3l143.2-85l143.2,85c10,6,22.3-3,19.8-14.3l-36.6-162.5l125.1-109.9C516.2,191.9,511.5,177.5,499.9,176.4" fill="url('#starOrder3')"/>
																</svg>
															</span>
															<span class="star-rating">
																<svg class="star" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 488.2">
																	<linearGradient id="starOrder4" x1="0" x2="100%" y1="0" y2="0">
																		<stop offset="100%" stop-color="#ffdc64"></stop>
																		<stop offset="100%" stop-color="#dddddd"></stop>
																	</linearGradient>
																	<path d="M499.9,176.4L334.1,161L268.2,8c-4.6-10.7-19.8-10.7-24.4,0l-65.9,153L12.1,176.4c-11.6,1.1-16.3,15.5-7.5,23.2l125.1,109.9L93,472c-2.6,11.4,9.7,20.3,19.7,14.3l143.2-85l143.2,85c10,6,22.3-3,19.8-14.3l-36.6-162.5l125.1-109.9C516.2,191.9,511.5,177.5,499.9,176.4" fill="url('#starOrder4')"/>
																</svg>
															</span>
															<span class="star-rating">
																<svg class="star" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 488.2">
																	<linearGradient id="starOrder5" x1="0" x2="100%" y1="0" y2="0">
																		<stop offset="0%" stop-color="#ffdc64"></stop>
																		<stop offset="0%" stop-color="#dddddd"></stop>
																	</linearGradient>
																	<path d="M499.9,176.4L334.1,161L268.2,8c-4.6-10.7-19.8-10.7-24.4,0l-65.9,153L12.1,176.4c-11.6,1.1-16.3,15.5-7.5,23.2l125.1,109.9L93,472c-2.6,11.4,9.7,20.3,19.7,14.3l143.2-85l143.2,85c10,6,22.3-3,19.8-14.3l-36.6-162.5l125.1-109.9C516.2,191.9,511.5,177.5,499.9,176.4" fill="url('#starOrder5')"/>
																</svg>
															</span>
														</div>
													</div>
													<span class="review-box__id"> id1234**</span>
													<span class="review-box__date">2020-01-01</span>
												</div>
												<div class="review-box__body">
													<div class="review-box__texts">
														<p class="review-box__text">
															상품 후기 내용이 옵니다. 상품 후기 내용이 옵니다. 상품 후기 내용이 옵니다. 상품 후기 내용이 옵니다. 상품 후기 내용이 옵니다. 상품
															후기 내용이 옵니다. 상품 후기 내용이 옵니다. 상품 후기 내용이 옵니다. 상품 후기 내용이 옵니다. 상품 후기 내용이 옵니다.
															상품 후기 내용이 옵니다. 상품 후기 내용이 옵니다.
														</p>
													</div>
													<div class="review-box__flex">
														<ul class="review-box__photo-lists">
															<li class="review-box__photo-list"><div class="review-box__photo" role="button"><img src="" alt=""></div></li>
															<li class="review-box__photo-list"><div class="review-box__photo" role="button"><img src="" alt=""></div></li>
															<li class="review-box__photo-list"><div class="review-box__photo" role="button"><img src="" alt=""></div></li>
															<li class="review-box__photo-list"><div class="review-box__photo" role="button"><img src="" alt=""></div></li>
															<li class="review-box__photo-list"><div class="review-box__photo" role="button"><img src="" alt=""></div></li>
															<li class="review-box__photo-list"><div class="review-box__photo" role="button"><img src="" alt=""></div></li>
														</ul>
														<div class="review-box__thumbup">
															<span class="review-box__thumbup-title">이 리뷰가 도움이 되었나요?</span>
															<button class="button" type="button" aria-pressed="true"><span>네 <b>+5</b></span></button>
															<button class="button" type="button" aria-pressed="false"><span>아니요 <b>-1</b></span></button>
														</div>
													</div>
												</div>
											</div>
											<div class="review-box">
												<div class="review-box__head">
													<div class="review-box__evaluation">
														<!-- 별점 평가 -->
														<div class="star-rating-container">
															<span class="star-rating">
																<svg class="star" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 488.2">
																	<linearGradient id="starOrder1" x1="0" x2="100%" y1="0" y2="0">
																		<stop offset="100%" stop-color="#ffdc64"></stop>
																		<stop offset="100%" stop-color="#dddddd"></stop>
																	</linearGradient>
																	<path d="M499.9,176.4L334.1,161L268.2,8c-4.6-10.7-19.8-10.7-24.4,0l-65.9,153L12.1,176.4c-11.6,1.1-16.3,15.5-7.5,23.2l125.1,109.9L93,472c-2.6,11.4,9.7,20.3,19.7,14.3l143.2-85l143.2,85c10,6,22.3-3,19.8-14.3l-36.6-162.5l125.1-109.9C516.2,191.9,511.5,177.5,499.9,176.4" fill="url('#starOrder1')"/>
																</svg>
															</span>
															<span class="star-rating">
																<svg class="star" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 488.2">
																	<linearGradient id="starOrder2" x1="0" x2="100%" y1="0" y2="0">
																		<stop offset="100%" stop-color="#ffdc64"></stop>
																		<stop offset="100%" stop-color="#dddddd"></stop>
																	</linearGradient>
																	<path d="M499.9,176.4L334.1,161L268.2,8c-4.6-10.7-19.8-10.7-24.4,0l-65.9,153L12.1,176.4c-11.6,1.1-16.3,15.5-7.5,23.2l125.1,109.9L93,472c-2.6,11.4,9.7,20.3,19.7,14.3l143.2-85l143.2,85c10,6,22.3-3,19.8-14.3l-36.6-162.5l125.1-109.9C516.2,191.9,511.5,177.5,499.9,176.4" fill="url('#starOrder2')"/>
																</svg>
															</span>
															<span class="star-rating">
																<svg class="star" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 488.2">
																	<linearGradient id="starOrder3" x1="0" x2="100%" y1="0" y2="0">
																		<stop offset="100%" stop-color="#ffdc64"></stop>
																		<stop offset="100%" stop-color="#dddddd"></stop>
																	</linearGradient>
																	<path d="M499.9,176.4L334.1,161L268.2,8c-4.6-10.7-19.8-10.7-24.4,0l-65.9,153L12.1,176.4c-11.6,1.1-16.3,15.5-7.5,23.2l125.1,109.9L93,472c-2.6,11.4,9.7,20.3,19.7,14.3l143.2-85l143.2,85c10,6,22.3-3,19.8-14.3l-36.6-162.5l125.1-109.9C516.2,191.9,511.5,177.5,499.9,176.4" fill="url('#starOrder3')"/>
																</svg>
															</span>
															<span class="star-rating">
																<svg class="star" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 488.2">
																	<linearGradient id="starOrder4" x1="0" x2="100%" y1="0" y2="0">
																		<stop offset="100%" stop-color="#ffdc64"></stop>
																		<stop offset="100%" stop-color="#dddddd"></stop>
																	</linearGradient>
																	<path d="M499.9,176.4L334.1,161L268.2,8c-4.6-10.7-19.8-10.7-24.4,0l-65.9,153L12.1,176.4c-11.6,1.1-16.3,15.5-7.5,23.2l125.1,109.9L93,472c-2.6,11.4,9.7,20.3,19.7,14.3l143.2-85l143.2,85c10,6,22.3-3,19.8-14.3l-36.6-162.5l125.1-109.9C516.2,191.9,511.5,177.5,499.9,176.4" fill="url('#starOrder4')"/>
																</svg>
															</span>
															<span class="star-rating">
																<svg class="star" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 488.2">
																	<linearGradient id="starOrder5" x1="0" x2="100%" y1="0" y2="0">
																		<stop offset="0%" stop-color="#ffdc64"></stop>
																		<stop offset="0%" stop-color="#dddddd"></stop>
																	</linearGradient>
																	<path d="M499.9,176.4L334.1,161L268.2,8c-4.6-10.7-19.8-10.7-24.4,0l-65.9,153L12.1,176.4c-11.6,1.1-16.3,15.5-7.5,23.2l125.1,109.9L93,472c-2.6,11.4,9.7,20.3,19.7,14.3l143.2-85l143.2,85c10,6,22.3-3,19.8-14.3l-36.6-162.5l125.1-109.9C516.2,191.9,511.5,177.5,499.9,176.4" fill="url('#starOrder5')"/>
																</svg>
															</span>
														</div>
													</div>
													<span class="review-box__id"> id1234**</span>
													<span class="review-box__date">2020-01-01</span>
												</div>
												<div class="review-box__body">
													<div class="review-box__texts">
														<p class="review-box__text">
															상품 후기 내용이 옵니다. 상품 후기 내용이 옵니다. 상품 후기 내용이 옵니다. 상품 후기 내용이 옵니다. 상품 후기 내용이 옵니다. 상품
															후기 내용이 옵니다. 상품 후기 내용이 옵니다. 상품 후기 내용이 옵니다. 상품 후기 내용이 옵니다. 상품 후기 내용이 옵니다.
															상품 후기 내용이 옵니다. 상품 후기 내용이 옵니다.
														</p>
													</div>
													<div class="review-box__flex">
														<ul class="review-box__photo-lists">
															<li class="review-box__photo-list"><div class="review-box__photo" role="button"><img src="" alt=""></div></li>
															<li class="review-box__photo-list"><div class="review-box__photo" role="button"><img src="" alt=""></div></li>
															<li class="review-box__photo-list"><div class="review-box__photo" role="button"><img src="" alt=""></div></li>
															<li class="review-box__photo-list"><div class="review-box__photo" role="button"><img src="" alt=""></div></li>
															<li class="review-box__photo-list"><div class="review-box__photo" role="button"><img src="" alt=""></div></li>
															<li class="review-box__photo-list"><div class="review-box__photo" role="button"><img src="" alt=""></div></li>
														</ul>
														<div class="review-box__thumbup">
															<span class="review-box__thumbup-title">이 리뷰가 도움이 되었나요?</span>
															<button class="button" type="button" aria-pressed="true"><span>네 <b>+5</b></span></button>
															<button class="button" type="button" aria-pressed="false"><span>아니요 <b>-1</b></span></button>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<!-- 상품 상세: 상품문의 -->
							<div class="product-details__content">
								<div class="product-qna-container" data-tab-contents="qna">
									<div class="product-qna__head">
										<div class="product-qna__form">
											<form class="product-qna__form-inner" action="">
												<div class="product-qna__search">
													<input class="input" type="text" name="" value="">
													<button class="button" type="submit"></button>
												</div>
												<select class="product-qna__select" name="">
													<option value="" disabled selected>항목을 선택해주세요.</option>
													<option value="">옵션1</option>
													<option value="">옵션2</option>
												</select>
											</form>
										</div>
										<div class="product-qna__buttons">
											<div class="button button--border-primary">문의하기</div>
										</div>
									</div>
									<div class="product-qna__body">
										<div class="product-table">
											<div class="tr">
												<div class="th">번호</div>
												<div class="th">제목</div>
												<div class="th">작성자</div>
												<div class="th">작성일</div>
												<div class="th">처리현황</div>
											</div>
											<div class="tr">
												<div class="td">3</div>
												<div class="td"><a class="link" href="">상품문의 제목이 옵니다. 상품문의 제목이 옵니다. 상품문의 제목이 옵니다. 상품문의 제목이 옵니다.  상품문의 제목이 옵니다.  상품문의 제목이 옵니다. </a></div>
												<div class="td"> id1234**</div>
												<div class="td">2020-01-01</div>
												<div class="td">답변대기</div>
											</div>
											<div class="tr">
												<div class="td">2</div>
												<div class="td"><a class="link" href="">상품문의 제목이 옵니다.</a></div>
												<div class="td"> id1234**</div>
												<div class="td">2020-01-01</div>
												<div class="td">답변대기</div>
											</div>
											<div class="tr">
												<div class="td">1</div>
												<div class="td"><a class="link" href="">상품문의 제목이 옵니다.</a></div>
												<div class="td"> id1234**</div>
												<div class="td">2020-01-01</div>
												<div class="td">답변대기</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<!-- 상품 상세: 배송 -->
							<div class="product-details__content">
								<div class="product-delivery-container" data-tab-contents="delivery">
									<div class="product-delivery">
										<div class="product-delivery__col">
											<span class="title">반품</span>
											<span class="subtitle">상품이 마음에 안 들어 돈으로 환불 받을 경우</span>
											<div class="table">
												<div class="tr">
													<div class="th">반품신청​</div>
													<div class="td">구매자 책임사유시 반품/배송비 결제​</div>
												</div>
												<div class="tr">
													<div class="th">발송하기​</div>
													<div class="td">1~2일 이내 택배기사 방문​</div>
												</div>
												<div class="tr">
													<div class="th">반품접수 완료​</div>
													<div class="td">벨스리몰에 반품상품 전달​</div>
												</div>
												<div class="tr">
													<div class="th">반품완료​</div>
													<div class="td">확인 후 환불처리​</div>
												</div>
											</div>
										</div>
										<div class="product-delivery__col">
											<span class="title">교환</span>
											<span class="subtitle">주문상품과 다르게 배송되어 다른 상품과 바꿀때​</span>
											<div class="table">
												<div class="tr">
													<div class="th">교환신청​</div>
													<div class="td">구매자 책임사유시 반품/배송비 결제​</div>
												</div>
												<div class="tr">
													<div class="th">발송하기​</div>
													<div class="td">1~2일 이내 택배기사 방문​</div>
												</div>
												<div class="tr">
													<div class="th">교환접수​</div>
													<div class="td">밸리스몰에 교환상품 전달​</div>
												</div>
												<div class="tr">
													<div class="th">상품 재배송​</div>
													<div class="td">확인 후 교환​</div>
												</div>
												<div class="tr">
													<div class="th">교환완료​</div>
													<div class="td"></div>
												</div>
											</div>
										</div>
										<div class="product-delivery__col product-delivery__col--merged">
											<span class="title">주의사항​</span>
											<ul class="ul">
												<li>미성년자가 물품을 구매하는 경우, 법정대리인이 동의하지 않으면 미성년자 본인 또는 법정대리인이 구매를 취소할 수 있습니다.​</li>
												<li>안전검사 대상 공산품’을 구매하시는 경우 품질경영 및 공산품 안전관리법에 따른 안전검사 표시가 있는 제품인지 확인하시기 바랍니다.​</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
                    </section>
				</section>
			</div>
		</main>

		<!-- 푸터 -->
		<main-footer></main-footer>
	</div>
</template>

<script>
import MainHeader from "../../../components/client/inc/main-header.vue";
import MainFooter from "../../../components/client/inc/main-footer.vue";

export default{
	components: {
		MainHeader,
		MainFooter
	},
	created() {
	},
	mounted() {
		this.init();
	},
	methods : {
		init : function() {
		}
	},
}
</script>

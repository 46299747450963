<template>
    <div class="wrap wrap--sub">
        <!-- 헤더 -->
        <main-header></main-header>

        <!-- 메인 -->
        <main class="main" role="main">
            <div class="collection-index-container">
                <div class="page-head">
                    <div class="page-head__inner">
                        <h1 class="page-head__title">FAMILY SHOP</h1>
                        <ul class="breadcrumb">
                            <li class="breadcrumb__list">
                                항상 고객을 생각하는 마음으로 최선을 다하는
                                디아이디
                            </li>
                            <!-- <li class="breadcrumb__list">컬렉션</li>
                            <li class="breadcrumb__list">국내</li>
                            <li class="breadcrumb__list">디앤디 컬렉션 인덱스</li> -->
                        </ul>
                    </div>
                </div>

                <div class="family-section1">
                    <div class="container">
                        <h4>
                            자그마한 샘플북이 아닌
                            <strong>직접보고 선택하는</strong>
                        </h4>
                        <h2 class="color-point">패밀리샵 논현 GRAND OPEN</h2>
                        <p>
                            학동역 도보 2분거리에 위치한 디아이디 패밀리샵
                            논현에서 600여개의 벽지랙으로 <br class="pc" />실제
                            벽지에 바른 모습으로 확인하세요.
                        </p>
                        <div class="family-box">
                            <p>
                                “ 패밀리샵에는
                                <strong class="color-point"
                                    >3,000여종이 넘는 국내,수출벽지가
                                    전시</strong
                                >되어 있으며 <br class="pc" /><strong
                                    class="color-point"
                                    >실제 집처럼 크게 도배/연출</strong
                                >되어 있습니다. <br class="pc" />또한
                                <strong class="color-point"
                                    >현장에서 바로 구매 가능</strong
                                >합니다. ”
                            </p>
                        </div>
                        <div class="box-list">
                            <h3>디아이디 패밀리샵에서 제공하는 4가지 혜택!</h3>
                            <ul>
                                <li>
                                    <div class="box-style">
                                        <span
                                            ><small>600여개 벽지랙으로</small
                                            ><br />실제 시공느낌 확인</span
                                        >
                                    </div>
                                </li>
                                <li>
                                    <div class="box-style">
                                        <span
                                            ><small>벽지 전문가의</small
                                            ><br />컨설팅 제공</span
                                        >
                                    </div>
                                </li>
                                <li>
                                    <div class="box-style">
                                        <span
                                            ><small>국내시장에서 볼수없는</small
                                            ><br />수출벽지 구매가능</span
                                        >
                                    </div>
                                </li>
                                <li>
                                    <div class="box-style">
                                        <span
                                            ><small>셀프시공존에서</small
                                            ><br />실제 시공체험</span
                                        >
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="family-section2">
                    <div class="container">
                        <h3>
                            <strong class="color-point">3000</strong>여개의
                            다양한 벽지와
                            <strong class="color-point">600</strong>여개의
                            벽지랙으로 <br class="pc" />다양한 종류의 벽지를
                            실제 우리집의 시공 모습을 미리 볼 수 있습니다.
                        </h3>
                        <div class="align-center">
                            <img
                                src="/images/sub/family/family-section1-img.png"
                                alt="이미지"
                            />
                        </div>
                    </div>
                </div>

                <div class="family-section3">
                    <div class="container">
                        <div class="fs3-left">
                            <h2 class="page-title30">
                                다양한 패턴의 수출벽지 <br class="pc" />바로구매
                                가능!
                            </h2>
                            <p class="page-text22">
                                개별 취급점에서 구입할 수 없는
                                <br class="pc" />다양한 패턴의 수입벽지를
                                <br class="pc" />패밀리샵에서만 방문상담 후
                                <br class="pc" />구매가능합니다.
                            </p>
                            <img
                                src="/images/sub/family/family-section3-img.png"
                                alt="이미지"
                            />
                        </div>
                        <div class="fs3-right">
                            <div class="align-right">
                                <img
                                    src="/images/sub/family/family-section3-img2.png"
                                    alt="이미지"
                                />
                            </div>
                        </div>
                        <div class="img-block align-center">
                            <img
                                src="/images/sub/family/family-section3-img3.png"
                                alt="이미지"
                            />
                        </div>
                    </div>
                </div>

                <div class="family-section4">
                    <div class="container">
                        <div class="fs4-left">
                            <h2 class="page-title30">
                                벽지전문가의 <br class="pc" />수준높은 컨설팅
                                제공
                            </h2>
                            <p class="page-text22">
                                벽지전문가의 수준높은 벽지에 특화된
                                <br class="pc" />전문상담으로 성공적인
                                인테리어의 <br class="pc" />시작을 경험하실 수
                                있습니다.
                            </p>
                        </div>
                        <div class="fs4-right">
                            <div class="align-right">
                                <img
                                    src="/images/sub/family/family-section4-img.png"
                                    alt="이미지"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="family-section8">
                    <div class="container">
                        <h3>
                            <strong class="color-point"
                                >다양한 매칭 서비스</strong
                            >로 내집에 어울리는
                            <strong class="color-point">벽지선택 가이드</strong
                            >를 제시해 드립니다.
                        </h3>
                        <div class="align-center">
                            <img
                                src="/images/sub/family/family-section9-img.png"
                                alt="이미지"
                            />
                        </div>
                    </div>
                </div>

                <div class="family-section5">
                    <div class="container">
                        <div class="fs5-left">
                            <h2 class="page-title30">
                                셀프벽지 체험으로 <br class="pc" />DIY 시공
                                스킬UP!
                            </h2>
                            <p class="page-text22">
                                셀프벽지 시공으로 나홀로 <br class="pc" />벽지
                                시공에 도전!
                            </p>
                        </div>
                        <div class="fs5-right">
                            <div class="align-right">
                                <img
                                    src="/images/sub/family/family-section5-img.png"
                                    alt="이미지"
                                />
                            </div>
                        </div>
                        <div class="img-block align-center">
                            <img
                                src="/images/sub/family/family-section5-img2.png"
                                alt="이미지"
                            />
                        </div>
                    </div>
                </div>

                <div class="family-section6">
                    <div class="container">
                        <div class="fs6-left">
                            <h2 class="page-title30">
                                디아이디 패밀리샵은 <br class="pc" />가까이에
                                있습니다.
                            </h2>
                            <p class="page-text22">
                                학동역에서 도보 2분 거리에 위치해
                                <br class="pc" />교통이 편리합니다.
                            </p>
                            <ul>
                                <li><strong>학동역 7호선 </strong>도보 2분</li>
                                <!-- <li><strong>선정릉역 9호선 </strong>도보 1분</li>
                                <li><strong>강남구청역 7호선 </strong>도보 15분</li>
                                <li><strong>선릉역 2호선 </strong>도보 14분</li> -->
                            </ul>
                        </div>
                        <div class="fs6-right">
                            <div class="img-block align-right">
                                <img
                                    src="/images/sub/family/family-section6-img.png"
                                    alt="이미지"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="family-section7">
                    <div class="container align-center">
                        <h2 class="page-title30 color-grey7">패밀리샵 문의</h2>
                        <a href="/community/interior" class="box-label"
                            >벽지상담 및 문의</a
                        >
                        <p class="page-text16 weight-5 color-grey7">
                            02. 2038. 8803
                        </p>
                        <p class="page-text16 weight-5 color-grey9">
                            서울특별시 강남구 언주로129길 8 재정빌딩 1층
                        </p>
                        <p class="page-text16 weight-5 color-grey9">
                            10:00 - 19:00 공휴일, 일요일 휴무
                        </p>
                    </div>
                </div>
            </div>
        </main>

        <!-- 푸터 -->
        <main-footer></main-footer>
    </div>
</template>

<script>
import MainHeader from "@/components/client/inc/main-header.vue";
import MainFooter from "@/components/client/inc/main-footer.vue";

export default {
    components: {
        MainHeader,
        MainFooter,
    },
    created() {},
    mounted() {
        this.init();
    },
};
</script>
